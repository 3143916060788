import React from "react";
import LabelValuePair from "../common/LabelValuePair";
import {
  AuditLog,
  AuditLogType,
  AuditLogTypeLabels,
} from "../../models/AuditLog";

import {
  Modal,
  ModalDialog,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/joy";

import CloseIcon from "@mui/icons-material/Close";

const ViewDownloadFileAuditLogDetailsModal: React.FC<{
  auditLog: AuditLog | null;
  open: boolean;
  onClose: () => void;
  organizationId: string;
}> = ({ auditLog, open, onClose, organizationId }) => {
  const generateDynamicLink = (entityId: string | null, type: AuditLogType) => {
    if (entityId === null) return `/organization/${organizationId}/audit-logs`;

    switch (type) {
      case AuditLogType.CaseFileDownload:
        return `/organization/${organizationId}/case/${entityId}`;
      case AuditLogType.IntakeFileDownload:
        return `/organization/${organizationId}/intake/${entityId}`;
      case AuditLogType.FirmDocumentDownload:
        return `/organization/${organizationId}/firm-documents-folders/${entityId}`;
      case AuditLogType.ProviderFileDownload:
        return `/organization/${organizationId}/provider/${entityId}`;
      case AuditLogType.TemplateFileDownload:
        return `/organization/${organizationId}/template/${entityId}`;
      default:
        return `/organization/${organizationId}/audit-logs`;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: {
          xs: "100vw",
          lg: "calc(100vw + 15vw)",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {auditLog ? (
        <ModalDialog
          variant="outlined"
          sx={{ width: "65%", maxHeight: "90vh", overflow: "hidden" }}
        >
          <DialogTitle>
            {AuditLogTypeLabels[auditLog.eventType]}
            <IconButton
              variant="plain"
              color="neutral"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          {auditLog.additionalData ? (
            (() => {
              try {
                const additionalDataAsJson = JSON.parse(
                  auditLog.additionalData
                );
                return (
                  <>
                    <Stack direction="row" gap={0.5}>
                      <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                        Migration Entity ID:
                      </Typography>
                      <Typography level="body-sm">
                        <Link
                          href={generateDynamicLink(
                            additionalDataAsJson.MigrationEntityId,
                            auditLog.eventType
                          )}
                          underline="hover"
                        >
                          {additionalDataAsJson.MigrationEntityId}
                        </Link>
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={0.5}>
                      <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                        File ID:
                      </Typography>
                      <Typography level="body-sm">
                        {additionalDataAsJson.FileId}
                      </Typography>
                    </Stack>
                  </>
                );
              } catch (error) {
                return (
                  <Typography level="body-sm">
                    Failed to parse additionalData
                  </Typography>
                );
              }
            })()
          ) : (
            <></>
          )}

          <LabelValuePair
            label="Peroformed By"
            value={auditLog.performerEmailAddress}
          />

          <LabelValuePair label="Event Date" value={auditLog.eventDate} />
        </ModalDialog>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default ViewDownloadFileAuditLogDetailsModal;
