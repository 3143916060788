/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Order } from "../../helpers/TableHelpers";
import {
  MigrationStatus,
  MigrationStatusColors,
  MigrationStatusLabels,
} from "../../models/MigrationStatus";
import { FlagType } from "../../models/ClientConfiguration/FlaggingConfiguration";
import { ClearableSelect } from "../common/ClearableSelect";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import ConfirmTemplateBlockUpdateModal from "./ConfirmTemplateBlockUpdateModal";
import { Button, IconButton, Select, Skeleton } from "@mui/joy";
import { JobRequestStatus } from "../../models/Jobs/JobRequestStatus";
import { DeleteTemplateRequest } from "../../models/Jobs/JobRequest";
import { JobType } from "../../models/Jobs/JobType";
import { JobEntityType } from "../../models/Jobs/JobEntityType";
import FlagTypeChip from "../common/FlagTypeChip";

// icons
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import TableViewIcon from "@mui/icons-material/TableView";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BlockIcon from "@mui/icons-material/Block";
import { Template } from "../../models/Template";
import { ActiveTemplateJobsExplorer } from "../../pages/TemplatesPage";
import TemplatesRepository from "../../repositories/TemplatesRepository";

interface TemplatesTableProps {
  organizationId: string;
  activeJobsExplorer: ActiveTemplateJobsExplorer;
  newTemplatesForDeletion: DeleteTemplateRequest[];
  setNewTemplatesForDeletion: React.Dispatch<
    React.SetStateAction<DeleteTemplateRequest[]>
  >;
  newTemplatesForExport: Template[];
  setNewTemplatesForExport: React.Dispatch<React.SetStateAction<Template[]>>;
}

const TemplatesTable: React.FC<TemplatesTableProps> = ({
  organizationId,
  activeJobsExplorer,
  newTemplatesForDeletion,
  setNewTemplatesForDeletion,
  newTemplatesForExport,
  setNewTemplatesForExport,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [lockFilter, setLockFilter] = React.useState<boolean | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForTemplateNameId, setSearchForTemplateNameId] = useState<
    string | null
  >("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [rows, setRows] = useState(0);
  const [dirtyTemplateId, setDirtyTemplateId] = useState<string>("");
  const [dirtyTemplateNameId, setDirtyTemplateNameId] = useState<string>("");
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] =
    React.useState<boolean>(false);

  const templatesRepository = new TemplatesRepository();
  const navigate = useNavigate();
  const templatesQuery = useQuery(
    [
      "templates",
      organizationId,
      searchForTemplateNameId,
      page,
      rowsPerPage,
      statusFilter,
      lockFilter,
      sort,
      order,
    ],
    async () => {
      const templates = await templatesRepository.getTemplates({
        organizationId: organizationId,
        templateNameId: searchForTemplateNameId
          ? [searchForTemplateNameId]
          : null,
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        isLocked: lockFilter,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
      return templates;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (templatesQuery.data?.data) {
      setTemplates(templatesQuery.data?.data);
      setRows(templatesQuery.data.paging?.totalCount ?? 0);
    }
  }, [templatesQuery]);
  const handleSearch = (newTemplateNameId: string) => {
    setSearchForTemplateNameId(newTemplateNameId ?? null);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const handleDelete = (templateId: string, templateNameId: string) => {
    if (!disabledRows.has(templateId)) {
      setNewTemplatesForDeletion((prevRows) => [
        ...prevRows,
        {
          migrationEntityId: templateId,
          templateNameId: templateNameId,
          status: JobRequestStatus.New,
          type: JobType.Delete,
          entityType: JobEntityType.TemplatesLibrary,
          organizationId: organizationId,
        } as DeleteTemplateRequest,
      ]);
    }
  };

  const handleAddTemplateForExport = (templateEntity: Template) => {
    if (!disabledRows.has(templateEntity.id)) {
      setNewTemplatesForExport((prevRows) => [...prevRows, templateEntity]);
    }
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? rows : Math.min(rows, (page + 1) * rowsPerPage);
  };

  const getDisabledRows = () => {
    switch (activeJobsExplorer) {
      case ActiveTemplateJobsExplorer.DeleteTemplates:
        return new Set(newTemplatesForDeletion.map((c) => c.migrationEntityId));
      case ActiveTemplateJobsExplorer.ExportCsv:
        return new Set(newTemplatesForExport.map((c) => c.id));
    }
  };

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const getActiveButtonForExplorer = (
    templateEntity: Template,
    isDisabled: boolean
  ) => {
    switch (activeJobsExplorer) {
      case ActiveTemplateJobsExplorer.DeleteTemplates:
        return (
          <IconButton
            aria-label="Delete"
            disabled={
              isDisabled ||
              (!templateEntity.hasRootFolderInfo &&
                templateEntity.status === MigrationStatus.Finished)
            }
            size="sm"
            color="danger"
            onClick={() =>
              handleDelete(templateEntity.id, templateEntity.templateNameId)
            }
          >
            <DeleteIcon />
          </IconButton>
        );
      case ActiveTemplateJobsExplorer.ExportCsv:
        return (
          <IconButton
            aria-label="Csv"
            disabled={isDisabled}
            size="sm"
            color="success"
            onClick={() => handleAddTemplateForExport(templateEntity)}
          >
            <TableViewIcon />
          </IconButton>
        );
      default:
        <></>;
    }
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={MigrationStatusColors[statusFilter as MigrationStatus]}
          onChange={(newValue) => {
            setPage(0);
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(MigrationStatus)
            .filter((v) => v !== MigrationStatus.NotFound)
            .map((status, idx) => {
              const migrationStatus = status as MigrationStatus;
              return (
                <Option
                  key={idx}
                  value={migrationStatus}
                  color={MigrationStatusColors[migrationStatus]}
                >
                  {MigrationStatusLabels[migrationStatus]}
                </Option>
              );
            })}
        </ClearableSelect>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Lock</FormLabel>
        <ClearableSelect
          value={null}
          onChange={(newValue) => {
            setPage(0);
            if (newValue === null || newValue === "all") {
              setLockFilter(null);
            } else {
              setLockFilter(newValue === "locked");
            }
          }}
          size="sm"
          placeholder="Filter by lock status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option key={1} value={"locked"}>
            Locked
          </Option>
          <Option key={2} value={"unlocked"}>
            Unlocked
          </Option>
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );

  const handleBlockModal = (
    templateNameId: string,
    templateId: string,
    isBlocked: boolean,
    isOpen: boolean
  ) => {
    setIsBlockModalOpen(isOpen);
    setDirtyTemplateNameId(templateNameId);
    setDirtyTemplateId(templateId);
    setIsBlocked(isBlocked);
  };

  const { isError, isFetching } = templatesQuery;
  const disabledRows = getDisabledRows();

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          pb: 0.5,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for template</FormLabel>
          <Input
            size="sm"
            placeholder="Template name id"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleSearch(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="TemplatesTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "1.5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>
                <th
                  style={{
                    width: "15%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Name Id"}
                    sortProperty={"templateType"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "8%", padding: "12px 6px" }}>Lock</th>

                <th style={{ width: "14%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "10%", padding: "12px 6px" }}>Progress</th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Files"}
                    sortProperty={"totalNumberOfFiles"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Size"}
                    sortProperty={"totalFilesSize"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "13%", padding: "12px 6px" }}>Flag Type</th>

                <th style={{ width: "18%", padding: "12px 6px" }}>
                  Blocked By
                </th>

                <th style={{ width: "20%", padding: "12px 6px" }}>
                  Flag Status
                </th>

                <th
                  style={{
                    width: "10%",
                    maxWidth: 10,
                    padding: "12px 6px",
                  }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {templates.map((templateEntity) => {
                const migratedPercentage =
                  templateEntity.statistics.totalNumberOfFiles > 0
                    ? (templateEntity.statistics.filesMigrated /
                        templateEntity.statistics.totalNumberOfFiles) *
                      100
                    : -1;
                const isDisabled = disabledRows.has(templateEntity.id);

                return (
                  <tr
                    key={templateEntity.id}
                    onDoubleClick={() =>
                      navigate(
                        `/organization/${organizationId}/template/${templateEntity.id}`
                      )
                    }
                    style={{ opacity: isDisabled ? 0.2 : 1 }}
                  >
                    <td>{templateEntity.templateType}</td>
                    <td>
                      <Typography level="body-sm">
                        {templateEntity.templateNameId}
                      </Typography>
                    </td>
                    <td>
                      {templateEntity.isLocked ? (
                        <Chip variant="soft" size="sm" color="primary">
                          Locked
                        </Chip>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <MigrationStatusChip status={templateEntity.status} />
                    </td>
                    <td>
                      {templateEntity.statistics.totalNumberOfFiles > 0 ? (
                        <Chip
                          sx={{ textAlign: "center" }}
                          variant="soft"
                          size="sm"
                          color={
                            migratedPercentage > 99.99 ? "success" : "primary"
                          }
                        >
                          {migratedPercentage % 1 > 0.99 ||
                          migratedPercentage % 1 < 0.01
                            ? `${migratedPercentage.toFixed(0)}%`
                            : `${migratedPercentage.toFixed(2)}%`}
                        </Chip>
                      ) : (
                        <Chip variant="soft" size="sm" color="danger">
                          No files
                        </Chip>
                      )}
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {templateEntity.statistics.totalNumberOfFiles ?? 0}
                      </Chip>
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {templateEntity.statistics.filesSizeInMb.toFixed(2)} MB
                      </Chip>
                    </td>

                    <td>
                      {templateEntity.flagType ? (
                        <FlagTypeChip flagType={templateEntity.flagType} />
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      <Typography
                        level="body-sm"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {templateEntity.flaggedBy}
                      </Typography>
                    </td>

                    <td>
                      {templateEntity.flagType == null ||
                      templateEntity.flagType ===
                        FlagType.MigrationEntityBlockedByUser ? (
                        <Button
                          sx={{
                            width: "100%",
                            maxWidth: 150,
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                          variant="soft"
                          aria-label="Block/Unblock"
                          disabled={isDisabled}
                          size="sm"
                          color={
                            templateEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? "lime"
                              : "warning"
                          }
                          startDecorator={
                            templateEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser ? (
                              <LockOpenIcon />
                            ) : (
                              <BlockIcon />
                            )
                          }
                          onClick={() => {
                            templateEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? handleBlockModal(
                                  templateEntity.templateNameId,
                                  templateEntity.id,
                                  false,
                                  true
                                )
                              : handleBlockModal(
                                  templateEntity.templateNameId,
                                  templateEntity.id,
                                  true,
                                  true
                                );
                          }}
                        >
                          {templateEntity.flagType ===
                          FlagType.MigrationEntityBlockedByUser
                            ? "Unblock"
                            : "Block"}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      {getActiveButtonForExplorer(templateEntity, isDisabled)}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>

                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>

      {/* Block Modal */}
      <ConfirmTemplateBlockUpdateModal
        open={isBlockModalOpen}
        isBlocked={isBlocked}
        templateNameId={dirtyTemplateNameId}
        onClose={() => {
          handleBlockModal("", "", false, false);
        }}
        onConfirm={async () => {
          await templatesRepository.updateTemplateBlock(
            dirtyTemplateId,
            isBlocked
          );
          await templatesQuery.refetch();
          handleBlockModal("", "", false, false);
        }}
      />
    </React.Fragment>
  );
};

export default TemplatesTable;
