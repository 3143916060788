import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  TabPanel,
  TabList,
  tabClasses,
  Divider,
} from "@mui/joy";
import { useParams } from "react-router-dom";
import TemplatesTable from "../components/Templates/TemplatesTable";
import DeleteTemplatesTable from "../components/Templates/DeleteTemplatesTable";
import { useState } from "react";
import { DeleteTemplateRequest } from "../models/Jobs/JobRequest";
import CreateExportTemplatesToCsvTable from "../components/Templates/CreateExportTemplatesToCsvTable";
import { Template } from "../models/Template";
import ExportTemplatesToCsvStatusTable from "../components/Templates/ExportTemplatesToCsvStatusTable";

export enum ActiveTemplateJobsExplorer {
  DeleteTemplates = 0,
  ExportCsv = 1,
}

const TemplatesPage = () => {
  const { organizationId } = useParams();
  const [newTemplatesForDeletion, setNewTemplatesForDeletion] = useState<
    DeleteTemplateRequest[]
  >([]);
  const [newTemplatesForExport, setNewTemplatesForExport] = useState<
    Template[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<ActiveTemplateJobsExplorer>(
    ActiveTemplateJobsExplorer.DeleteTemplates
  );
  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Templates</Typography>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <TemplatesTable
              organizationId={organizationId ?? ""}
              activeJobsExplorer={selectedTab}
              newTemplatesForDeletion={newTemplatesForDeletion}
              setNewTemplatesForDeletion={setNewTemplatesForDeletion}
              newTemplatesForExport={newTemplatesForExport}
              setNewTemplatesForExport={setNewTemplatesForExport}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveTemplateJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete templates
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveTemplateJobsExplorer.DeleteTemplates}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Card
                  sx={{
                    padding: 0,
                    border: 0,
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                >
                  <DeleteTemplatesTable
                    organizationId={organizationId ?? ""}
                    newTemplatesForDeletion={newTemplatesForDeletion}
                    setNewTemplatesForDeletion={setNewTemplatesForDeletion}
                  />
                </Card>
              </TabPanel>
              <TabPanel
                value={ActiveTemplateJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newTemplatesForExport.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateExportTemplatesToCsvTable
                        organizationId={organizationId ?? ""}
                        newTemplatesForExport={newTemplatesForExport}
                        setNewTemplatesForExport={setNewTemplatesForExport}
                      />
                      <Divider>
                        <b>Existing exports</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <ExportTemplatesToCsvStatusTable
                      organizationId={organizationId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TemplatesPage;
