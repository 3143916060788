import axios from "axios";
import React, { useEffect, useState } from "react";
interface GraphAdminConsentRequestState {
  tenantName: string;
  intactId: string | null;
  bindToOrganization: boolean;
}
const AdminConsentResultPage = () => {
  const [tenantId, setTenantId] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [consentGranted, setConsentGranted] = useState(false);
  const [apiResponded, setApiResponded] = useState(false);
  debugger;

  useEffect(() => {
    document.title = "Grant admin consent - Assembly Software";
    let favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      favicon = document.createElement("link");
      (favicon as HTMLLinkElement).rel = "icon";
      document.head.appendChild(favicon);
    }
    if (favicon instanceof HTMLLinkElement) {
      favicon.href = "/adminconsent/images/icons/favicon.png";
    }
    const styleSheet = document.createElement("link");
    styleSheet.href = "/adminconsent/css/main.css";
    styleSheet.rel = "stylesheet";
    const font = document.createElement("link");
    font.href =
      "/adminconsent/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
    font.rel = "stylesheet";
    document.head.appendChild(styleSheet);
    document.head.appendChild(font);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const adminConsentGranted =
      urlParams.get("admin_consent")?.toLowerCase() === "true";
    const tenant = urlParams.get("tenant");
    const stateParam = urlParams.get("state");
    setTenantId(tenant || "");
    const api_type = urlParams.get("api_type");
    if (api_type === "dropbox") {
      setApiResponded(true);
      setConsentGranted(adminConsentGranted);
      setTenantName(tenant || "");
    } else {
      if (stateParam) {
        const stateObject = JSON.parse(stateParam);
        const state: GraphAdminConsentRequestState =
          stateObject as GraphAdminConsentRequestState;
        if (state && adminConsentGranted && !apiResponded) {
          const apiUrl = process.env.REACT_APP_API_URL ?? "/";
          const api = axios.create({
            baseURL: apiUrl,
            timeout: 30000,
          });
          api
            .post(
              `/api/adminconsent?tenantId=${tenant}&intactId=${state.intactId}&tenantName=${state.tenantName}`
            )
            .then(() => setConsentGranted(true))
            .catch((error) => {
              console.error(error);
              setConsentGranted(false);
            })
            .finally(() => setApiResponded(true));
        } else {
          setApiResponded(true);
        }
        setTenantName(state.tenantName || "");
      } else {
        setApiResponded(true);
      }
      return () => {
        document.head.removeChild(styleSheet);
        document.head.removeChild(font);
      };
    }
  }, [apiResponded]);

  return (
    <div className="limiter">
      <div
        className="container-login100"
        style={{
          backgroundImage: `url('/adminconsent/images/backgrounds/background.png')`,
        }}
      >
        <div className="wrap-login100">
          <div className="login100-form-title p-b-24">
            <img
              width="250"
              height="48"
              src="/adminconsent/images/logos/assembly-logo.webp"
              alt="Assembly Logo"
            />
          </div>
          {consentGranted ? (
            <>
              <div
                className="p-b-12 fs-20 centered-text"
                hidden={!apiResponded}
              >
                Admin consent granted for tenant {tenantName}
              </div>
              <div className="fs-14 centered-text">Tenant id: {tenantId}</div>
            </>
          ) : (
            <div className="p-b-12 fs-20 centered-text" hidden={!apiResponded}>
              Admin consent for tenant {tenantName} could not be granted
            </div>
          )}
          <div className="container-login100-form-btn">
            <div className="wrap-login100-form-btn">
              <div className="login100-form-bgbtn"></div>
              <a className="login100-form-btn" href="/adminconsent">
                Go back
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminConsentResultPage;
