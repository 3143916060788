import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import TemplateFilesTable, {
  TemplateFilesTableRef,
} from "../components/Template/TemplateFilesTable";
import MigrationStatusChip from "../components/common/MigrationStatusChip";
import { TemplateWithStatistics } from "../models/Template";
import {
  MigrationStatus,
  MigrationStatusColors,
} from "../models/MigrationStatus";
import TemplatesRepository from "../repositories/TemplatesRepository";
import React from "react";
import DeleteTemplateFilesStatusTable from "../components/Template/DeleteTemplateFilesStatusTable";
import { TemplateFile } from "../models/TemplateFile";
import CreateDeleteTemplateFilesRequestTable from "../components/Template/CreateDeleteTemplateFilesRequestTable";
import ExportTemplateFilesToCsvStatusTable from "../components/Template/ExportTemplateFilesToCsvStatusTable";
import ConfirmTemplateFilesExportToCsvModal from "../components/Template/ConfirmTemplateFilesExportToCsvModal";
export enum ActiveTemplateFilesJobsExplorer {
  DeleteTemplateFiles = 0,
  ExportCsv = 1,
}
const TemplatePage = () => {
  const { organizationId, templateId } = useParams();
  const templatesRepository = new TemplatesRepository();
  const [templateEntity, setTemplate] = useState<
    TemplateWithStatistics | undefined
  >(undefined);

  const [isConfirmExportModalOpen, setIsConfirmExportModalOpen] =
    React.useState<boolean>(false);
  const [newTemplateFilesForDeletion, setNewTemplateFilesForDeletion] =
    useState<TemplateFile[]>([]);
  const [selectedTab, setSelectedTab] =
    useState<ActiveTemplateFilesJobsExplorer>(
      ActiveTemplateFilesJobsExplorer.DeleteTemplateFiles
    );
  const templateQuery = useQuery(
    ["template", templateId],
    async () => {
      return await templatesRepository.getTemplate(templateId ?? "");
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    }
  );
  const templateFilesTableRef = useRef<TemplateFilesTableRef>(null);

  useEffect(() => {
    if (templateQuery.data) {
      setTemplate(templateQuery.data);
    }
  }, [templateQuery.data, templateId]);

  const { isLoading, isError } = templateQuery;
  if (isLoading || isError || !!!templateEntity) {
    return <></>;
  }

  const startCurrentViewExportToCsv = () => {
    templateFilesTableRef.current?.exportCurrentViewToCsv();
  };

  const migratedPercentage =
    templateEntity.statistics.totalNumberOfFiles > 0 &&
    templateEntity.statistics.filesSizeInMb > 0
      ? (templateEntity.statistics.filesMigrated /
          templateEntity.statistics.totalNumberOfFiles) *
        100
      : 0;

  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          width: "100%",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <Typography level="h2">
            Template {templateEntity.templateType}
            <MigrationStatusChip status={templateEntity.status} size={"md"} />
          </Typography>
          <Stack
            width="100%"
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack
              flex={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Started</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotStarted]}
                >
                  {templateEntity.statistics.totalNumberOfFiles -
                    (templateEntity.statistics.filesMigrated +
                      templateEntity.statistics.filesInterrupted +
                      templateEntity.statistics.filesNotFound)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Migrated</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Finished]}
                >
                  {templateEntity.statistics.filesMigrated}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Interrupted</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Interrupted]}
                >
                  {templateEntity.statistics.filesInterrupted}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Found</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotFound]}
                >
                  {templateEntity.statistics.filesNotFound}
                </Chip>
              </FormControl>

              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Files Count</FormLabel>
                <Chip variant="soft" size="sm" color="neutral">
                  {templateEntity.statistics.totalNumberOfFiles}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Size (MB)</FormLabel>
                <Chip variant="soft" size="sm" color="primary">
                  {templateEntity.statistics.filesSizeInMb.toFixed(2)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Progress</FormLabel>
                <Chip
                  sx={{ textAlign: "center" }}
                  variant="soft"
                  size="sm"
                  color={migratedPercentage > 99.99 ? "success" : "primary"}
                >
                  {migratedPercentage % 1 > 0.99 ||
                  migratedPercentage % 1 < 0.01
                    ? `${migratedPercentage.toFixed(0)}%`
                    : `${migratedPercentage.toFixed(2)}%`}
                </Chip>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <TemplateFilesTable
              ref={templateFilesTableRef}
              organizationId={organizationId ?? ""}
              templateId={templateId ?? ""}
              activeJobsExplorer={selectedTab}
              newTemplateFilesForDeletion={newTemplateFilesForDeletion}
              setNewTemplatesFilesForDeletion={setNewTemplateFilesForDeletion}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveTemplateFilesJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete files
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveTemplateFilesJobsExplorer.DeleteTemplateFiles}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newTemplateFilesForDeletion.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateDeleteTemplateFilesRequestTable
                        organizationId={organizationId ?? ""}
                        templateId={templateId ?? ""}
                        templateNameId={templateEntity.templateNameId}
                        newFilesForDeletion={newTemplateFilesForDeletion}
                        setNewTemplateFilesForDeletion={
                          setNewTemplateFilesForDeletion
                        }
                      />
                      <Divider>
                        <b>Deletion status</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <DeleteTemplateFilesStatusTable
                      organizationId={organizationId ?? ""}
                      templateId={templateId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
              <TabPanel
                value={ActiveTemplateFilesJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  <Card
                    sx={{
                      padding: "1em 0 0 0",
                      border: 0,
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <Button onClick={() => setIsConfirmExportModalOpen(true)}>
                      Export current files view
                    </Button>
                    <ExportTemplateFilesToCsvStatusTable
                      organizationId={organizationId ?? ""}
                      templateId={templateId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>

      {/* Confirm CSV export modal */}
      {isConfirmExportModalOpen ? (
        <ConfirmTemplateFilesExportToCsvModal
          open={isConfirmExportModalOpen}
          onClose={() => {
            setIsConfirmExportModalOpen(false);
          }}
          onConfirm={async () => {
            startCurrentViewExportToCsv();
            setIsConfirmExportModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default TemplatePage;
