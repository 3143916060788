import { useTheme } from "@emotion/react";
import React from "react";
import StatisticsCard from "../common/StatisticsCard";
import {IntakesStatistics} from "../../models/Statistics/IntakesStatistics";

interface IntakesStatisticsCardProps {
  statistics: IntakesStatistics;
  displayOpenIntakeStatistics: boolean;
  displayClosedIntakeStatistics: boolean;
}

const IntakesStatisticsCard: React.FC<IntakesStatisticsCardProps> = ({
  statistics,
  displayOpenIntakeStatistics,
  displayClosedIntakeStatistics
}) => {
  const theme = useTheme() as any;

  let numberOfIntakes : number = 0;
  if (displayOpenIntakeStatistics) numberOfIntakes = numberOfIntakes + statistics.numberOfOpenIntakes;
  if (displayClosedIntakeStatistics) numberOfIntakes = numberOfIntakes + statistics.numberOfClosedIntakes;

  let intakesMigrated : number = 0;
  if (displayOpenIntakeStatistics) intakesMigrated = intakesMigrated + statistics.openIntakesMigrated;
  if (displayClosedIntakeStatistics) intakesMigrated = intakesMigrated + statistics.closedIntakesMigrated;

  let intakesInProgress : number = 0;
  if (displayOpenIntakeStatistics) intakesInProgress = intakesInProgress + statistics.openIntakesInProgress;
  if (displayClosedIntakeStatistics) intakesInProgress = intakesInProgress + statistics.closedIntakesInProgress;

  let intakesInterrupted : number = 0;
  if (displayOpenIntakeStatistics) intakesInterrupted = intakesInterrupted + statistics.openIntakesInterrupted;
  if (displayClosedIntakeStatistics) intakesInterrupted = intakesInterrupted + statistics.closedIntakesInterrupted;

  let intakesWithFlaggedStatus : number = 0;
  if (displayOpenIntakeStatistics) intakesWithFlaggedStatus = intakesWithFlaggedStatus + statistics.openIntakesFlagged;
  if (displayClosedIntakeStatistics) intakesWithFlaggedStatus = intakesWithFlaggedStatus + statistics.closedIntakesFlagged;

  let intakesResolved : number = 0;
  if (displayOpenIntakeStatistics) intakesResolved = intakesResolved + statistics.openIntakesResolved;
  if (displayClosedIntakeStatistics) intakesResolved = intakesResolved + statistics.closedIntakesResolved;

  let intakesAcknowledged : number = 0;
  if (displayOpenIntakeStatistics) intakesAcknowledged = intakesAcknowledged + statistics.openIntakesAcknowledged;
  if (displayClosedIntakeStatistics) intakesAcknowledged = intakesAcknowledged + statistics.closedIntakesAcknowledged;

  const intakesNotStarted = numberOfIntakes - (intakesMigrated + intakesInProgress + intakesInterrupted + intakesWithFlaggedStatus);
  const intakesFlagged = intakesWithFlaggedStatus - (intakesAcknowledged + intakesResolved);

  const intakeChartData = [
    {
      name: "Migrated",
      value: intakesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: intakesInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: intakesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: intakesNotStarted,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: intakesFlagged,
      color: theme.palette.primary.plainColor,
    },
    {
      name: "Allowed",
      value: intakesResolved,
      color: theme.palette.lime.solidBg,
    },
    {
      name: "Acknowledged",
      value: intakesAcknowledged,
      color: theme.palette.yellow.plainColor,
    },
  ];

  let totalNumberOfFiles : number = 0;
  if (displayOpenIntakeStatistics) totalNumberOfFiles = totalNumberOfFiles + statistics.totalNumberOfOpenIntakeFiles;
  if (displayClosedIntakeStatistics) totalNumberOfFiles = totalNumberOfFiles + statistics.totalNumberOfClosedIntakeFiles;

  let filesMigrated : number = 0;
  if (displayOpenIntakeStatistics) filesMigrated = filesMigrated + statistics.openIntakeFilesMigrated;
  if (displayClosedIntakeStatistics) filesMigrated = filesMigrated + statistics.closedIntakeFilesMigrated;

  let filesNotFound : number = 0;
  if (displayOpenIntakeStatistics) filesNotFound = filesNotFound + statistics.openIntakeFilesNotFound;
  if (displayClosedIntakeStatistics) filesNotFound = filesNotFound + statistics.closedIntakeFilesNotFound;

  let filesInterrupted : number = 0;
  if (displayOpenIntakeStatistics) filesInterrupted = filesInterrupted + statistics.openIntakeFilesInterrupted;
  if (displayClosedIntakeStatistics) filesInterrupted = filesInterrupted + statistics.closedIntakeFilesInterrupted;

  let filesFlagged : number = 0;
  if (displayOpenIntakeStatistics) filesFlagged = filesFlagged + statistics.openIntakeFilesFlagged;
  if (displayClosedIntakeStatistics) filesFlagged = filesFlagged + statistics.closedIntakeFilesFlagged;

  const filesNotStarted = totalNumberOfFiles - (filesMigrated + filesInterrupted + filesNotFound + filesFlagged);

  const filesChartData = [
    {
      name: "Migrated",
      value: filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: filesNotStarted,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: filesFlagged,
      color: theme.palette.primary.plainColor,
    },
  ];

  let dicomFilesFlagged : number = 0;
  if (displayOpenIntakeStatistics) dicomFilesFlagged = dicomFilesFlagged + statistics.dicomOpenIntakeFilesFlagged;
  if (displayClosedIntakeStatistics) dicomFilesFlagged = dicomFilesFlagged + statistics.dicomClosedIntakeFilesFlagged;

  let msgFilesFlagged : number = 0;
  if (displayOpenIntakeStatistics) msgFilesFlagged = msgFilesFlagged + statistics.msgOpenIntakeFilesFlagged;
  if (displayClosedIntakeStatistics) msgFilesFlagged = msgFilesFlagged + statistics.msgClosedIntakeFilesFlagged;

  let filesInIntakesWithOver10KFiles : number = 0;
  if (displayOpenIntakeStatistics) filesInIntakesWithOver10KFiles = filesInIntakesWithOver10KFiles + statistics.intakeFilesInOpenIntakesWithOver10KFiles;
  if (displayClosedIntakeStatistics) filesInIntakesWithOver10KFiles = filesInIntakesWithOver10KFiles + statistics.intakeFilesInClosedIntakesWithOver10KFiles;

  let filesInIntakesBlockedByUser : number = 0;
  if (displayOpenIntakeStatistics) filesInIntakesBlockedByUser = filesInIntakesBlockedByUser + statistics.intakeFilesInOpenIntakesBlockedByUser;
  if (displayClosedIntakeStatistics) filesInIntakesBlockedByUser = filesInIntakesBlockedByUser + statistics.intakeFilesInClosedIntakesBlockedByUser;

  let filesMovedToFirmDocuments : number = 0;
  if (displayOpenIntakeStatistics) filesMovedToFirmDocuments = filesMovedToFirmDocuments + statistics.openIntakeFilesMovedToFirmDocuments;
  if (displayClosedIntakeStatistics) filesMovedToFirmDocuments = filesMovedToFirmDocuments + statistics.closedIntakeFilesMovedToFirmDocuments;

  const flagsChartData = [
    {
      name: "DICOM",
      value: dicomFilesFlagged,
      color: theme.palette.primary[300],
    },
    {
      name: "Flagged Extensions",
      value: msgFilesFlagged,
      color: theme.palette.primary[400],
    },
    {
      name: "Intakes >10K",
      value: filesInIntakesWithOver10KFiles,
      color: theme.palette.primary[500],
    },
    {
      name: "Blocked by user",
      value: filesInIntakesBlockedByUser,
      color: theme.palette.warning[400],
    },
    {
      name: "Moved To Firm Documents",
      value: filesMovedToFirmDocuments,
      color: theme.palette.success.plainColor,
    }
  ];

  let totalFilesSizeInGb : number = 0;
  if (displayOpenIntakeStatistics) totalFilesSizeInGb = totalFilesSizeInGb + statistics.totalOpenIntakeFilesSizeInGb;
  if (displayClosedIntakeStatistics) totalFilesSizeInGb = totalFilesSizeInGb + statistics.totalClosedIntakeFilesSizeInGb;

  let totalMigratedFilesSizeInGb : number = 0;
  if (displayOpenIntakeStatistics) totalMigratedFilesSizeInGb = totalMigratedFilesSizeInGb + statistics.totalMigratedOpenIntakeFilesSizeInGb;
  if (displayClosedIntakeStatistics) totalMigratedFilesSizeInGb = totalMigratedFilesSizeInGb + statistics.totalMigratedClosedIntakeFilesSizeInGb;

  let totalFilesSizeInMb : number = 0;
  if (displayOpenIntakeStatistics) totalFilesSizeInMb = totalFilesSizeInMb + statistics.totalOpenIntakeFilesSizeInMb;
  if (displayClosedIntakeStatistics) totalFilesSizeInMb = totalFilesSizeInMb + statistics.totalClosedIntakeFilesSizeInMb;

  let totalMigratedFilesSizeInMb : number = 0;
  if (displayOpenIntakeStatistics) totalMigratedFilesSizeInMb = totalMigratedFilesSizeInMb + statistics.totalMigratedOpenIntakeFilesSizeInMb;
  if (displayClosedIntakeStatistics) totalMigratedFilesSizeInMb = totalMigratedFilesSizeInMb + statistics.totalMigratedClosedIntakeFilesSizeInMb;

  let filesMovedToFirmDocumentsSizeInGb : number = 0;
  if (displayOpenIntakeStatistics) filesMovedToFirmDocumentsSizeInGb = filesMovedToFirmDocumentsSizeInGb + statistics.openIntakeFilesMovedToFirmDocumentsSizeInGb;
  if (displayClosedIntakeStatistics) filesMovedToFirmDocumentsSizeInGb = filesMovedToFirmDocumentsSizeInGb + statistics.closedIntakeFilesMovedToFirmDocumentsSizeInGb;

  let filesMovedToFirmDocumentsSizeInMb : number = 0;
  if (displayOpenIntakeStatistics) filesMovedToFirmDocumentsSizeInMb = filesMovedToFirmDocumentsSizeInMb + statistics.openIntakeFilesMovedToFirmDocumentsSizeInMb;
  if (displayClosedIntakeStatistics) filesMovedToFirmDocumentsSizeInMb = filesMovedToFirmDocumentsSizeInMb + statistics.closedIntakeFilesMovedToFirmDocumentsSizeInMb;

  let notResolvedFlaggedFilesSizeInGb : number = 0;
  if (displayOpenIntakeStatistics) notResolvedFlaggedFilesSizeInGb = notResolvedFlaggedFilesSizeInGb + statistics.notResolvedFlaggedOpenIntakeFilesSizeInGb;
  if (displayClosedIntakeStatistics) notResolvedFlaggedFilesSizeInGb = notResolvedFlaggedFilesSizeInGb + statistics.notResolvedFlaggedClosedIntakeFilesSizeInGb;

  let notResolvedFlaggedFilesSizeInMb : number = 0;
  if (displayOpenIntakeStatistics) notResolvedFlaggedFilesSizeInMb = notResolvedFlaggedFilesSizeInMb + statistics.notResolvedFlaggedOpenIntakeFilesSizeInMb;
  if (displayClosedIntakeStatistics) notResolvedFlaggedFilesSizeInMb = notResolvedFlaggedFilesSizeInMb + statistics.notResolvedFlaggedClosedIntakeFilesSizeInMb;
  
  return (
    <StatisticsCard
      migrationChartData={{ data: intakeChartData, label: "Intakes" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      fileFlagsChartData={{ data: flagsChartData, label: "Flags"}}
      title={"Intakes Statistics"}
      totalFilesSizeInGb={totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={totalMigratedFilesSizeInMb}
      totalFilesMovedToFirmDocsSizeInGb={filesMovedToFirmDocumentsSizeInGb}
      totalFilesMovedToFirmDocsSizeInMb={filesMovedToFirmDocumentsSizeInMb}
      notResolvedFlaggedFilesSizeInGb={notResolvedFlaggedFilesSizeInGb}
      notResolvedFlaggedFilesSizeInMb={notResolvedFlaggedFilesSizeInMb}
      showFlaggedSizeInfo={true}
    />
  );
};

export default IntakesStatisticsCard;
