import { useTheme } from "@emotion/react";
import React from "react";
import { CasesStatistics } from "../../models/Statistics/CasesStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface CasesStatisticsCardProps {
  statistics: CasesStatistics;
  displayOpenCaseStatistics: boolean;
  displayClosedCaseStatistics: boolean;
}

const CasesStatisticsCard: React.FC<CasesStatisticsCardProps> = ({
  statistics,
  displayOpenCaseStatistics,
  displayClosedCaseStatistics
}) => {
  const theme = useTheme() as any;
  
  let numberOfCases : number = statistics.numberOfCaseFolders;
  if (displayOpenCaseStatistics) numberOfCases = numberOfCases + statistics.numberOfOpenCases;
  if (displayClosedCaseStatistics) numberOfCases = numberOfCases + statistics.numberOfClosedCases;
  
  let casesMigrated : number = statistics.caseFoldersMigrated;
  if (displayOpenCaseStatistics) casesMigrated = casesMigrated + statistics.openCasesMigrated;
  if (displayClosedCaseStatistics) casesMigrated = casesMigrated + statistics.closedCasesMigrated;

  let casesInProgress : number = statistics.caseFoldersInProgress;
  if (displayOpenCaseStatistics) casesInProgress = casesInProgress + statistics.openCasesInProgress;
  if (displayClosedCaseStatistics) casesInProgress = casesInProgress + statistics.closedCasesInProgress;

  let casesInterrupted : number = statistics.caseFoldersInterrupted;
  if (displayOpenCaseStatistics) casesInterrupted = casesInterrupted + statistics.openCasesInterrupted;
  if (displayClosedCaseStatistics) casesInterrupted = casesInterrupted + statistics.closedCasesInterrupted;
  
  let casesWithFlaggedStatus : number = statistics.caseFoldersFlagged;
  if (displayOpenCaseStatistics) casesWithFlaggedStatus = casesWithFlaggedStatus + statistics.openCasesFlagged;
  if (displayClosedCaseStatistics) casesWithFlaggedStatus = casesWithFlaggedStatus + statistics.closedCasesFlagged;

  let casesResolved : number = statistics.caseFoldersResolved;
  if (displayOpenCaseStatistics) casesResolved = casesResolved + statistics.openCasesResolved;
  if (displayClosedCaseStatistics) casesResolved = casesResolved + statistics.closedCasesResolved;

  let casesAcknowledged : number = statistics.caseFoldersAcknowledged;
  if (displayOpenCaseStatistics) casesAcknowledged = casesAcknowledged + statistics.openCasesAcknowledged;
  if (displayClosedCaseStatistics) casesAcknowledged = casesAcknowledged + statistics.closedCasesAcknowledged;
  
  const casesNotStarted = numberOfCases - (casesMigrated + casesInProgress + casesInterrupted + casesWithFlaggedStatus);
  const casesFlagged = casesWithFlaggedStatus - (casesAcknowledged + casesResolved);
  
  const caseChartData = [
    {
      name: "Migrated",
      value: casesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: casesInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: casesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: casesNotStarted,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: casesFlagged,
      color: theme.palette.primary.plainColor,
    },
    {
      name: "Allowed",
      value: casesResolved,
      color: theme.palette.lime.solidBg,
    },
    {
      name: "Acknowledged",
      value: casesAcknowledged,
      color: theme.palette.yellow.plainColor,
    },
  ];

  let totalNumberOfFiles : number = statistics.totalNumberOfCaseFolderFiles;
  if (displayOpenCaseStatistics) totalNumberOfFiles = totalNumberOfFiles + statistics.totalNumberOfOpenCaseFiles;
  if (displayClosedCaseStatistics) totalNumberOfFiles = totalNumberOfFiles + statistics.totalNumberOfClosedCaseFiles;

  let filesMigrated : number = statistics.caseFolderFilesMigrated;
  if (displayOpenCaseStatistics) filesMigrated = filesMigrated + statistics.openCaseFilesMigrated;
  if (displayClosedCaseStatistics) filesMigrated = filesMigrated + statistics.closedCaseFilesMigrated;

  let filesNotFound : number = statistics.caseFolderFilesNotFound;
  if (displayOpenCaseStatistics) filesNotFound = filesNotFound + statistics.openCaseFilesNotFound;
  if (displayClosedCaseStatistics) filesNotFound = filesNotFound + statistics.closedCaseFilesNotFound;

  let filesInterrupted : number = statistics.caseFolderFilesInterrupted;
  if (displayOpenCaseStatistics) filesInterrupted = filesInterrupted + statistics.openCaseFilesInterrupted;
  if (displayClosedCaseStatistics) filesInterrupted = filesInterrupted + statistics.closedCaseFilesInterrupted;

  let filesFlagged : number = statistics.caseFolderFilesFlagged;
  if (displayOpenCaseStatistics) filesFlagged = filesFlagged + statistics.openCaseFilesFlagged;
  if (displayClosedCaseStatistics) filesFlagged = filesFlagged + statistics.closedCaseFilesFlagged;

  const filesNotStarted = totalNumberOfFiles - (filesMigrated + filesInterrupted + filesNotFound + filesFlagged);
  
  const filesChartData = [
    {
      name: "Migrated",
      value: filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: filesNotStarted,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: filesFlagged,
      color: theme.palette.primary.plainColor,
    },
  ];

  let dicomFilesFlagged : number = statistics.dicomCaseFolderFilesFlagged;
  if (displayOpenCaseStatistics) dicomFilesFlagged = dicomFilesFlagged + statistics.dicomOpenCaseFilesFlagged;
  if (displayClosedCaseStatistics) dicomFilesFlagged = dicomFilesFlagged + statistics.dicomClosedCaseFilesFlagged;

  let msgFilesFlagged : number = statistics.msgCaseFolderFilesFlagged;
  if (displayOpenCaseStatistics) msgFilesFlagged = msgFilesFlagged + statistics.msgOpenCaseFilesFlagged;
  if (displayClosedCaseStatistics) msgFilesFlagged = msgFilesFlagged + statistics.msgClosedCaseFilesFlagged;

  let filesInCasesWithOver10KFiles : number = statistics.caseFilesInCaseFoldersWithOver10KFiles;
  if (displayOpenCaseStatistics) filesInCasesWithOver10KFiles = filesInCasesWithOver10KFiles + statistics.caseFilesInOpenCasesWithOver10KFiles;
  if (displayClosedCaseStatistics) filesInCasesWithOver10KFiles = filesInCasesWithOver10KFiles + statistics.caseFilesInClosedCasesWithOver10KFiles;

  let filesInCasesBlockedByUser : number = statistics.caseFilesInCaseFoldersBlockedByUser;
  if (displayOpenCaseStatistics) filesInCasesBlockedByUser = filesInCasesBlockedByUser + statistics.caseFilesInOpenCasesBlockedByUser;
  if (displayClosedCaseStatistics) filesInCasesBlockedByUser = filesInCasesBlockedByUser + statistics.caseFilesInClosedCasesBlockedByUser;

  let filesMovedToFirmDocuments : number = statistics.caseFolderFilesMovedToFirmDocuments;
  if (displayOpenCaseStatistics) filesMovedToFirmDocuments = filesMovedToFirmDocuments + statistics.openCaseFilesMovedToFirmDocuments;
  if (displayClosedCaseStatistics) filesMovedToFirmDocuments = filesMovedToFirmDocuments + statistics.closedCaseFilesMovedToFirmDocuments;
  
  const flagsChartData = [
    {
      name: "DICOM",
      value: dicomFilesFlagged,
      color: theme.palette.primary[300],
    },
    {
      name: "Flagged Extensions",
      value: msgFilesFlagged,
      color: theme.palette.primary[400],
    },
    {
      name: "Cases >10K",
      value: filesInCasesWithOver10KFiles,
      color: theme.palette.primary[500],
    },
    {
      name: "Blocked by user",
      value: filesInCasesBlockedByUser,
      color: theme.palette.warning[400],
    },
    {
      name: "Moved To Firm Documents",
      value: filesMovedToFirmDocuments,
      color: theme.palette.success.plainColor,
    }
  ];

  let totalFilesSizeInGb : number = statistics.totalCaseFolderFilesSizeInGb;
  if (displayOpenCaseStatistics) totalFilesSizeInGb = totalFilesSizeInGb + statistics.totalOpenCaseFilesSizeInGb;
  if (displayClosedCaseStatistics) totalFilesSizeInGb = totalFilesSizeInGb + statistics.totalClosedCaseFilesSizeInGb;

  let totalMigratedFilesSizeInGb : number = statistics.totalMigratedCaseFolderFilesSizeInGb;
  if (displayOpenCaseStatistics) totalMigratedFilesSizeInGb = totalMigratedFilesSizeInGb + statistics.totalMigratedOpenCaseFilesSizeInGb;
  if (displayClosedCaseStatistics) totalMigratedFilesSizeInGb = totalMigratedFilesSizeInGb + statistics.totalMigratedClosedCaseFilesSizeInGb;

  let totalFilesSizeInMb : number = statistics.totalCaseFolderFilesSizeInMb;
  if (displayOpenCaseStatistics) totalFilesSizeInMb = totalFilesSizeInMb + statistics.totalOpenCaseFilesSizeInMb;
  if (displayClosedCaseStatistics) totalFilesSizeInMb = totalFilesSizeInMb + statistics.totalClosedCaseFilesSizeInMb;

  let totalMigratedFilesSizeInMb : number = statistics.totalMigratedCaseFolderFilesSizeInMb;
  if (displayOpenCaseStatistics) totalMigratedFilesSizeInMb = totalMigratedFilesSizeInMb + statistics.totalMigratedOpenCaseFilesSizeInMb;
  if (displayClosedCaseStatistics) totalMigratedFilesSizeInMb = totalMigratedFilesSizeInMb + statistics.totalMigratedClosedCaseFilesSizeInMb;

  let filesMovedToFirmDocumentsSizeInGb : number = statistics.caseFolderFilesMovedToFirmDocumentsSizeInGb;
  if (displayOpenCaseStatistics) filesMovedToFirmDocumentsSizeInGb = filesMovedToFirmDocumentsSizeInGb + statistics.openCaseFilesMovedToFirmDocumentsSizeInGb;
  if (displayClosedCaseStatistics) filesMovedToFirmDocumentsSizeInGb = filesMovedToFirmDocumentsSizeInGb + statistics.closedCaseFilesMovedToFirmDocumentsSizeInGb;

  let filesMovedToFirmDocumentsSizeInMb : number = statistics.caseFolderFilesMovedToFirmDocumentsSizeInMb;
  if (displayOpenCaseStatistics) filesMovedToFirmDocumentsSizeInMb = filesMovedToFirmDocumentsSizeInMb + statistics.openCaseFilesMovedToFirmDocumentsSizeInMb;
  if (displayClosedCaseStatistics) filesMovedToFirmDocumentsSizeInMb = filesMovedToFirmDocumentsSizeInMb + statistics.closedCaseFilesMovedToFirmDocumentsSizeInMb;

  let notResolvedFlaggedFilesSizeInGb : number = statistics.notResolvedFlaggedCaseFolderFilesSizeInGb;
  if (displayOpenCaseStatistics) notResolvedFlaggedFilesSizeInGb = notResolvedFlaggedFilesSizeInGb + statistics.notResolvedFlaggedOpenCaseFilesSizeInGb;
  if (displayClosedCaseStatistics) notResolvedFlaggedFilesSizeInGb = notResolvedFlaggedFilesSizeInGb + statistics.notResolvedFlaggedClosedCaseFilesSizeInGb;

  let notResolvedFlaggedFilesSizeInMb : number = statistics.notResolvedFlaggedCaseFolderFilesSizeInMb;
  if (displayOpenCaseStatistics) notResolvedFlaggedFilesSizeInMb = notResolvedFlaggedFilesSizeInMb + statistics.notResolvedFlaggedOpenCaseFilesSizeInMb;
  if (displayClosedCaseStatistics) notResolvedFlaggedFilesSizeInMb = notResolvedFlaggedFilesSizeInMb + statistics.notResolvedFlaggedClosedCaseFilesSizeInMb;
  
  return (
    <StatisticsCard
      migrationChartData={{ data: caseChartData, label: "Cases" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      fileFlagsChartData={{ data: flagsChartData, label: "Flags"}}
      title={"Cases Statistics"}
      totalFilesSizeInGb={totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={totalMigratedFilesSizeInMb}
      totalFilesMovedToFirmDocsSizeInGb={filesMovedToFirmDocumentsSizeInGb}
      totalFilesMovedToFirmDocsSizeInMb={filesMovedToFirmDocumentsSizeInMb}
      notResolvedFlaggedFilesSizeInGb={notResolvedFlaggedFilesSizeInGb}
      notResolvedFlaggedFilesSizeInMb={notResolvedFlaggedFilesSizeInMb}
      showFlaggedSizeInfo={true}
    />
  );
};

export default CasesStatisticsCard;
