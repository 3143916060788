import React from "react";
import ConfirmationModal from "../common/ConfirmationModal";

interface ConfirmTemplateFlagUpdateModalProps {
  open: boolean;
  templateNameId: string;
  isBlocked: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmTemplateBlockUpdateModal: React.FC<
  ConfirmTemplateFlagUpdateModalProps
> = ({ open, templateNameId, isBlocked, onClose, onConfirm }) => {
  const getMessage = (): string => {
    if (isBlocked) {
      return `Are you sure you want to block template ${templateNameId} migration?`;
    } else {
      return `Are you sure you want to unblock template ${templateNameId} migration?`;
    }
  };

  const getStatusColor = (): "warning" | "lime" => {
    if (isBlocked) {
      return "warning";
    } else {
      return "lime";
    }
  };

  const getButtonText = (): string => {
    if (isBlocked) {
      return "Block";
    } else {
      return "Unblock";
    }
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      message={getMessage()}
      statusColor={getStatusColor()}
      buttonText={getButtonText()}
    />
  );
};

export default ConfirmTemplateBlockUpdateModal;
