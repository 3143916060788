import { useState } from "react";
import { useParams } from "react-router-dom";
import OutlinedDiv from "../components/common/OutlinedDiv";
import {
  formatDate,
  formatTime,
  getFormattedDate,
} from "../helpers/StringFormatters";
import AuditLogTable from "../components/AuditLogs/AuditLogTable";
import ErrorSnackbar from "../components/common/ErrorSnackbar";
import { AuditLogQueryParams } from "../repositories/AuditLogsRepository";
import { AuditLogType } from "../models/AuditLog";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Switch,
  Typography,
  IconButton,
} from "@mui/joy";

import CloseIcon from "@mui/icons-material/Close";

const AuditLogsPage = () => {
  const auditLogsQueryKey = "auditLogs";
  const { organizationId } = useParams();

  const [
    fetchOrganizationConfigurationChanges,
    setFetchOrganizationConfigurationChanges,
  ] = useState(true);
  const [fetchClientConfigurationChanges, setFetchClientConfigurationChanges] =
    useState(true);
  const [fetchCaseExports, setFetchCaseExports] = useState(true);
  const [fetchCaseFileExports, setFetchCaseFileExports] = useState(true);
  const [fetchCaseFileDownloads, setFetchCaseFileDownloads] = useState(true);
  const [fetchFirmDocumentsFolderExports, setFetchFirmDocumentsFoldersExports] =
    useState(true);
  const [fetchFirmDocumentsExports, setFetchFirmDocumentsExports] =
    useState(true);
  const [fetchFirmDocumentDownloads, setFetchFirmDocumentDownloads] =
    useState(true);
  const [fetchIntakeFileDownloads, setFetchIntakeFileDownloads] =
    useState(true);
  const [fetchProviderExports, setFetchProviderExports] = useState(true);
  const [fetchProviderFileExports, setFetchProviderFileExports] =
    useState(true);
  const [fetchProviderFileDownloads, setFetchProviderFileDownloads] =
    useState(true);
  const [fetchTemplateExports, setFetchTemplateExports] = useState(true);
  const [fetchTemplateFileExports, setFetchTemplateFileExports] =
    useState(true);
  const [fetchTemplateFileDownloads, setFetchTemplateFileDownloads] =
    useState(true);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const now = new Date();
  const today = new Date(now);
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 7);
  yesterday.setMinutes(yesterday.getMinutes() + 1);
  today.setMinutes(today.getMinutes() + 1);

  const [queryParams, setQueryParams] = useState<AuditLogQueryParams>({
    eventTypes: [
      AuditLogType.ClientConfigurationChange,
      AuditLogType.OrganizationConfigurationChange,
      AuditLogType.CasesExport,
      AuditLogType.CaseFilesExport,
      AuditLogType.CaseFileDownload,
      AuditLogType.IntakeFileDownload,
      AuditLogType.FirmDocumentsFoldersExport,
      AuditLogType.FirmDocumentsExport,
      AuditLogType.FirmDocumentDownload,
      AuditLogType.ProvidersExport,
      AuditLogType.ProviderFilesExport,
      AuditLogType.ProviderFileDownload,
      AuditLogType.TemplatesExport,
      AuditLogType.TemplateFilesExport,
      AuditLogType.TemplateFileDownload,
    ],
    fromDate: formatDate(yesterday),
    toDate: formatDate(today),
    fromTime: formatTime(yesterday),
    toTime: formatTime(today),
  });

  const [shouldFetchAuditLogs, setShouldFetchAuditLogs] = useState(true);

  const handleDateChange =
    (field: "fromDate" | "toDate") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedDate = event.target.value
        ? getFormattedDate(event.target.value, "yyyy-MM-dd")
        : null;

      setQueryParams((prev) => ({ ...prev, [field]: formattedDate }));
    };

  const handleTimeChange =
    (field: "fromTime" | "toTime") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams((prev) => ({ ...prev, [field]: event.target.value }));
    };

  const handleResetToDefault = () => {
    setFetchOrganizationConfigurationChanges(true);
    setFetchClientConfigurationChanges(true);
    setFetchCaseExports(true);
    setFetchCaseFileExports(true);
    setFetchCaseFileDownloads(true);
    setFetchFirmDocumentsFoldersExports(true);
    setFetchFirmDocumentsExports(true);
    setFetchFirmDocumentDownloads(true);
    setFetchIntakeFileDownloads(true);
    setFetchProviderExports(true);
    setFetchProviderFileExports(true);
    setFetchProviderFileDownloads(true);
    setFetchTemplateExports(true);
    setFetchTemplateFileExports(true);
    setFetchTemplateFileDownloads(true);

    const eventTypes: AuditLogType[] = [];
    eventTypes.push(AuditLogType.ClientConfigurationChange);
    eventTypes.push(AuditLogType.OrganizationConfigurationChange);
    eventTypes.push(AuditLogType.CasesExport);
    eventTypes.push(AuditLogType.CaseFilesExport);
    eventTypes.push(AuditLogType.CaseFileDownload);
    eventTypes.push(AuditLogType.IntakeFileDownload);
    eventTypes.push(AuditLogType.FirmDocumentsFoldersExport);
    eventTypes.push(AuditLogType.FirmDocumentsExport);
    eventTypes.push(AuditLogType.FirmDocumentDownload);
    eventTypes.push(AuditLogType.ProvidersExport);
    eventTypes.push(AuditLogType.ProviderFilesExport);
    eventTypes.push(AuditLogType.ProviderFileDownload);
    eventTypes.push(AuditLogType.TemplatesExport);
    eventTypes.push(AuditLogType.TemplateFilesExport);
    eventTypes.push(AuditLogType.TemplateFileDownload);

    const now = new Date();
    const today = new Date(now);
    const yesterday = new Date(now);
    yesterday.setUTCDate(yesterday.getUTCDate() - 7);

    setQueryParams((prevState) => ({
      ...prevState,
      eventTypes: eventTypes,
      fromDate: formatDate(yesterday),
      toDate: formatDate(today),
      fromTime: formatTime(yesterday),
      toTime: formatTime(today),
    }));
  };

  const handleSubmit = () => {
    // validate filters
    if (
      !fetchOrganizationConfigurationChanges &&
      !fetchClientConfigurationChanges &&
      !fetchCaseExports &&
      !fetchCaseFileExports &&
      !fetchFirmDocumentsFolderExports &&
      !fetchFirmDocumentsExports &&
      !fetchCaseFileDownloads &&
      !fetchIntakeFileDownloads &&
      !fetchFirmDocumentDownloads &&
      !fetchTemplateExports &&
      !fetchTemplateFileExports &&
      !fetchTemplateFileDownloads
    ) {
      setShowErrorSnackbar(true);
      setErrorMessage(
        "At least one selected event type filter is required to search"
      );
      return;
    }

    if (!queryParams.fromDate) {
      setShowErrorSnackbar(true);
      setErrorMessage("Start date is required");
      return;
    }

    if (!queryParams.toDate && queryParams.toTime !== "") {
      setShowErrorSnackbar(true);
      setErrorMessage("End Date is required when end time is provided");
      return;
    }

    // logic
    setShowErrorSnackbar(false);
    setShouldFetchAuditLogs(true);
    const eventTypes: AuditLogType[] = [];

    if (fetchOrganizationConfigurationChanges)
      eventTypes.push(AuditLogType.OrganizationConfigurationChange);

    if (fetchClientConfigurationChanges)
      eventTypes.push(AuditLogType.ClientConfigurationChange);

    if (fetchCaseExports) eventTypes.push(AuditLogType.CasesExport);

    if (fetchCaseFileExports) eventTypes.push(AuditLogType.CaseFilesExport);

    if (fetchCaseFileDownloads) eventTypes.push(AuditLogType.CaseFileDownload);

    if (fetchFirmDocumentsFolderExports)
      eventTypes.push(AuditLogType.FirmDocumentsFoldersExport);

    if (fetchFirmDocumentsExports)
      eventTypes.push(AuditLogType.FirmDocumentsExport);

    if (fetchIntakeFileDownloads)
      eventTypes.push(AuditLogType.IntakeFileDownload);

    if (fetchFirmDocumentDownloads)
      eventTypes.push(AuditLogType.FirmDocumentDownload);

    if (fetchProviderExports) eventTypes.push(AuditLogType.ProvidersExport);

    if (fetchProviderFileExports)
      eventTypes.push(AuditLogType.ProviderFilesExport);

    if (fetchProviderFileDownloads)
      eventTypes.push(AuditLogType.ProviderFileDownload);

    if (fetchTemplateExports) eventTypes.push(AuditLogType.TemplatesExport);

    if (fetchTemplateFileExports)
      eventTypes.push(AuditLogType.TemplateFilesExport);

    if (fetchTemplateFileDownloads)
      eventTypes.push(AuditLogType.TemplateFileDownload);

    setQueryParams((prev) => ({
      ...prev,
      eventTypes,
    }));
  };

  const handleCloseErrorSnackbar = () => {
    setShowErrorSnackbar(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: 1,
          mb: 0.5,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Audit Logs</Typography>
        <Stack direction="row" spacing={1}>
          <Button
            size="md"
            sx={{ width: 145 }}
            onClick={() => handleResetToDefault()}
          >
            Reset To Default
          </Button>
          <Button
            size="md"
            sx={{ width: 145 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Stack>
      </Box>

      <Card variant={"outlined"} sx={{ mb: 1 }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="h4" component="div">
              Filters
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />

          <Grid container spacing={1} sx={{ flexGrow: 1, mb: 0.2 }}>
            <Grid xs={12} md={7} lg={7}>
              <OutlinedDiv label="Event Type">
                <Grid container spacing={3} sx={{ flexGrow: 1, mb: 0.2 }}>
                  <Grid xs={12} md={6} lg={6}>
                    <Stack spacing={1} sx={{ mb: -0.5 }}>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>
                          Organization Configuration Changes
                        </FormLabel>
                        <Switch
                          checked={fetchOrganizationConfigurationChanges}
                          onChange={(e) =>
                            setFetchOrganizationConfigurationChanges(
                              e.target.checked
                            )
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Client Configuration Changes</FormLabel>
                        <Switch
                          checked={fetchClientConfigurationChanges}
                          onChange={(e) =>
                            setFetchClientConfigurationChanges(e.target.checked)
                          }
                        />
                      </FormControl>
                      <Divider />
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Case Exports</FormLabel>
                        <Switch
                          checked={fetchCaseExports}
                          onChange={(e) =>
                            setFetchCaseExports(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Case File Exports</FormLabel>
                        <Switch
                          checked={fetchCaseFileExports}
                          onChange={(e) =>
                            setFetchCaseFileExports(e.target.checked)
                          }
                        />
                      </FormControl>
                      <Divider />
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Firm Documents Folders Exports</FormLabel>
                        <Switch
                          checked={fetchFirmDocumentsFolderExports}
                          onChange={(e) =>
                            setFetchFirmDocumentsFoldersExports(
                              e.target.checked
                            )
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Firm Documents Exports</FormLabel>
                        <Switch
                          checked={fetchFirmDocumentsExports}
                          onChange={(e) =>
                            setFetchFirmDocumentsExports(e.target.checked)
                          }
                        />
                      </FormControl>
                      <Divider />
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Provider Exports</FormLabel>
                        <Switch
                          checked={fetchProviderExports}
                          onChange={(e) =>
                            setFetchProviderExports(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Provider File Exports</FormLabel>
                        <Switch
                          checked={fetchProviderFileExports}
                          onChange={(e) =>
                            setFetchProviderFileExports(e.target.checked)
                          }
                        />
                      </FormControl>
                      <Divider />
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Template Exports</FormLabel>
                        <Switch
                          checked={fetchTemplateExports}
                          onChange={(e) =>
                            setFetchTemplateExports(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Template File Exports</FormLabel>
                        <Switch
                          checked={fetchTemplateFileExports}
                          onChange={(e) =>
                            setFetchTemplateFileExports(e.target.checked)
                          }
                        />
                      </FormControl>
                    </Stack>
                  </Grid>

                  <Grid xs={12} md={6} lg={6}>
                    <Stack spacing={1} sx={{ mb: -0.5 }}>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Case File Downloads</FormLabel>
                        <Switch
                          checked={fetchCaseFileDownloads}
                          onChange={(e) =>
                            setFetchCaseFileDownloads(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Intake File Downloads</FormLabel>
                        <Switch
                          checked={fetchIntakeFileDownloads}
                          onChange={(e) =>
                            setFetchIntakeFileDownloads(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Firm Document Downloads</FormLabel>
                        <Switch
                          checked={fetchFirmDocumentDownloads}
                          onChange={(e) =>
                            setFetchFirmDocumentDownloads(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Provider File Downloads</FormLabel>
                        <Switch
                          checked={fetchProviderFileDownloads}
                          onChange={(e) =>
                            setFetchProviderFileDownloads(e.target.checked)
                          }
                        />
                      </FormControl>
                      <FormControl
                        orientation="horizontal"
                        sx={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <FormLabel>Template File Downloads</FormLabel>
                        <Switch
                          checked={fetchTemplateFileDownloads}
                          onChange={(e) =>
                            setFetchTemplateFileDownloads(e.target.checked)
                          }
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>

            <Grid xs={12} md={5} lg={5}>
              <OutlinedDiv label="Range">
                <Grid container spacing={2} sx={{ mt: 0.1 }}>
                  <Grid xs={12}>
                    <OutlinedDiv label="Start Date">
                      <Stack direction="row" spacing={1}>
                        <Input
                          sx={{ width: "58%" }}
                          size={"sm"}
                          type="date"
                          value={
                            queryParams.fromDate
                              ? getFormattedDate(
                                  queryParams.fromDate,
                                  "yyyy-MM-dd"
                                )
                              : ""
                          }
                          onChange={handleDateChange("fromDate")}
                        />
                        <Input
                          sx={{ width: "42%" }}
                          size={"sm"}
                          type={"time"}
                          value={queryParams.fromTime}
                          onChange={handleTimeChange("fromTime")}
                          endDecorator={
                            <IconButton
                              sx={{ ml: -1 }}
                              onClick={() =>
                                setQueryParams({ ...queryParams, fromTime: "" })
                              }
                            >
                              <CloseIcon sx={{ color: "white" }} />
                            </IconButton>
                          }
                        />
                      </Stack>
                    </OutlinedDiv>
                  </Grid>

                  <Grid xs={12}>
                    <OutlinedDiv label="End Date">
                      <Stack direction="row" spacing={1}>
                        <Input
                          sx={{ width: "58%" }}
                          size={"sm"}
                          type="date"
                          value={
                            queryParams.toDate
                              ? getFormattedDate(
                                  queryParams.toDate,
                                  "yyyy-MM-dd"
                                )
                              : ""
                          }
                          onChange={handleDateChange("toDate")}
                        />
                        <Input
                          sx={{ width: "42%" }}
                          size={"sm"}
                          type={"time"}
                          value={queryParams.toTime}
                          onChange={handleTimeChange("toTime")}
                          endDecorator={
                            <IconButton
                              sx={{ ml: -1 }}
                              onClick={() =>
                                setQueryParams({ ...queryParams, toTime: "" })
                              }
                            >
                              <CloseIcon sx={{ color: "white" }} />
                            </IconButton>
                          }
                        />
                      </Stack>
                    </OutlinedDiv>
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <AuditLogTable
        queryKey={auditLogsQueryKey}
        organizationId={organizationId ?? ""}
        queryParams={queryParams}
        shouldFetchAuditLogs={shouldFetchAuditLogs}
        setShouldFetchAuditLogs={setShouldFetchAuditLogs}
      />

      <ErrorSnackbar
        open={showErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
        message={errorMessage}
      />
    </>
  );
};

export default AuditLogsPage;
