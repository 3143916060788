import {Box, Card, CardContent, Grid, Link, Tooltip, Stack, Typography, Skeleton, Button, Switch, Divider, FormLabel, FormControl } from "@mui/joy";
import React, {useEffect, useMemo, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import CasesStatisticsCard from "../components/Organization/CasesStatisticsCard";
import FirmDocumentsStatisticsCard from "../components/Organization/FirmDocumentsStatisticsCard";
import IntakesStatisticsCard from "../components/Organization/IntakesStatisticsCard";
import OverallStatisticsCard from "../components/Organization/OverallStatisticsCard";
import ProvidersStatisticsCard from "../components/Organization/ProvidersStatisticsCard";
import TemplatesStatisticsCard from "../components/Organization/TemplatesStatisticsCard";
import OutlinedDiv from "../components/common/OutlinedDiv";
import {convertUtcToLocal, roundToTwoDecimalPoints} from "../helpers";
import {OrganizationWithStatistics} from "../models/OrganizationWithStatistics";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import UsersRepository from "../repositories/UsersRepository";
import {User} from "../models/User";
import GenericAutocomplete from "../components/common/GenericAutocomplete";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RefreshIcon from '@mui/icons-material/Refresh';

interface OrganizationPageProps {
  organizationId: string;
}

const OrganizationPage: React.FC<OrganizationPageProps> = ({
  organizationId,
}) => {
  const [organization, setOrganization] = useState<OrganizationWithStatistics | undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<{id: string, label: string} | undefined>(undefined);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [displayStatisticsAccordingToMigrationStrategy, setDisplayStatisticsAccordingToMigrationStrategy] = useState(true);

  const queryClient = useQueryClient();
  const organizationsRepository = new OrganizationsRepository();
  const usersRepository = new UsersRepository();

  const organizationQuery = useQuery(
    ["organization", organizationId],
    async () => {
      return await organizationsRepository.getOrganization(organizationId);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    },
  );

  const userQuery = useQuery(
    "users",
    async () => {
      return await usersRepository.getUsers();
    },
    {
      cacheTime: 5 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (organizationQuery.data) {
      setOrganization(organizationQuery.data);
      setSelectedUser({
        id: organizationQuery.data.assignedUser.id,
        label: organizationQuery.data.assignedUser.username,
      });
    }
    
    if (userQuery.data)
      setUsers(userQuery.data.data);
  }, [
    userQuery.data,
    organizationQuery.data,
    organizationId
  ]);

  const displayOpenCaseAndIntakeStatistics = useMemo(() => {
    return !organization
      ? false
      : !displayStatisticsAccordingToMigrationStrategy || organization.displayOpenedCaseAndIntakeStatistics;
  }, [displayStatisticsAccordingToMigrationStrategy, organization]);

  const displayClosedCaseAndIntakeStatistics = useMemo(() => {
    return !organization
      ? false
      : !displayStatisticsAccordingToMigrationStrategy || organization.displayClosedCaseAndIntakeStatistics;
  }, [displayStatisticsAccordingToMigrationStrategy, organization]);

  const userOptions = users.map(user => ({
    id: user.id,
    label: user.username,
  }));
  
  const { isLoading, isError } = organizationQuery;
  if (isLoading || isError || !!!organization) {
    return <></>;
  }
  
  const {
    intactId,
    name, 
    sharepointSiteUrl,
    migrationStatistics 
  } = organization as OrganizationWithStatistics;
  
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = migrationStatistics;
  
  const allocatedSpace = migrationStatistics.allocatedSharepointSpaceInGb
    ? roundToTwoDecimalPoints(migrationStatistics.allocatedSharepointSpaceInGb)
    : null;
  
  const usedSpace = migrationStatistics.usedSharepointSpaceInGb
    ? roundToTwoDecimalPoints(migrationStatistics.usedSharepointSpaceInGb)
    : null;
  
  const usedPercentage =
    usedSpace && allocatedSpace
      ? `${((parseFloat(usedSpace) / parseFloat(allocatedSpace)) * 100).toFixed(
        2,
      )}%`
      : null;
  
  const shouldRenderSharepointInfo = sharepointSiteUrl || allocatedSpace || usedSpace;

  const handleSelectUser = async (selectedOption: { id: string; label: string }) => {
    setSelectedUser(selectedOption);
    try {
      await organizationsRepository.patchOrganizationAssignedUser(organizationId, selectedOption.id);
      await queryClient.invalidateQueries("organizations");
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefreshStatistics = async () => {
    setIsRecalculating(true);
    try {
      await organizationsRepository.RecalculateOrganizationStatistics(organizationId);
      await queryClient.invalidateQueries("organization");
    } catch (error) {
      console.error(error);
    } finally {
      setIsRecalculating(false);
    }
  };
  
  return (
    <>
      <Box width="100%" height={"100%"} padding={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', my: 1}}>
          <Typography level="h2" textColor="text.primary" sx={{ pb: 1 }}>
            {intactId} - {name}
          </Typography>
          <Box>
            <Stack direction="row" spacing={1}>
              <Skeleton
                animation="pulse"
                variant="inline"
                loading={userQuery.isLoading}
              >
                <GenericAutocomplete
                  options={userOptions}
                  inputValue={selectedUser ?? {id: '', label: ''}}
                  setValue={handleSelectUser}
                  placeholder="Assigned user"
                  width="350px"
                  startDecorator={
                    <Tooltip title="Assigned user" variant="solid">
                      <AssignmentIndIcon sx={{ fontSize: 26, mt: 0.4 }}/>
                    </Tooltip>
                  }
                />
              </Skeleton>
            </Stack>
 
          </Box>
        </Box>

        <Grid container spacing={2} height={"100%"}>
          {shouldRenderSharepointInfo ?  (
            <Grid xs={12} lg={12} display={"flex"}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <Typography level="h3" sx={{ paddingBottom: "0px" }}>
                  Sharepoint
                </Typography>
                <CardContent>
                  <OutlinedDiv label="">
                    {sharepointSiteUrl && (
                      <Link
                        href={sharepointSiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                      >
                        {sharepointSiteUrl}
                      </Link>
                    )}

                    {usedSpace && (
                      <Typography level="body-md" textColor="text.primary">
                        Used Space (GB): {usedSpace} ({usedPercentage})
                      </Typography>
                    )}
                    {allocatedSpace && (
                      <Typography level="body-md" textColor="text.primary">
                        Allocated Space (GB): {allocatedSpace}
                      </Typography>
                    )}
                  </OutlinedDiv>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
            
          <Card sx={{m:1}}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Typography level="h3" sx={{ flexGrow: 1, paddingBottom: "0px" }}>
                  Statistics
                </Typography>

                <Stack display="flex" alignItems="center" direction="row" spacing={1}>
                  <Card sx={{ py: 1, px: 1.25 }}>
                    <FormControl orientation="horizontal">
                      <FormLabel>Show statistics according to the migration strategy</FormLabel>
                      <Switch
                        checked={displayStatisticsAccordingToMigrationStrategy}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setDisplayStatisticsAccordingToMigrationStrategy(event.target.checked)
                        }
                      />
                    </FormControl>
                  </Card>

                  <Tooltip
                    title={`Statistics from: ${convertUtcToLocal(organization.lastStatisticsRefreshDate as string)}`}
                    variant="solid"
                    placement="bottom-start"
                    size="sm"
                  >
                    <Button
                      sx={{ width: 37, height: 37 }}
                      color="neutral"
                      variant="outlined"
                      loading={isRecalculating}
                      onClick={handleRefreshStatistics}
                    >
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                </Stack>
              </Box>
              <Divider sx={{ mt: 1, mb: 1.5 }} />
              
              <Grid container spacing={2} height={"100%"}>
                <Grid xs={12} lg={12} display={"flex"}>
                  <OverallStatisticsCard
                    displayOpenCaseAndIntakeStatistics={displayOpenCaseAndIntakeStatistics}
                    displayClosedCaseAndIntakeStatistics={displayClosedCaseAndIntakeStatistics}
                    statistics={migrationStatistics}
                  />
                </Grid>
                <Grid xs={12} lg={12} display={"flex"}>
                  <CasesStatisticsCard
                    displayOpenCaseStatistics={displayOpenCaseAndIntakeStatistics}
                    displayClosedCaseStatistics={displayClosedCaseAndIntakeStatistics}
                    statistics={casesStatistics}
                  />
                </Grid>
                <Grid xs={12} lg={12} display={"flex"}>
                  <IntakesStatisticsCard
                    displayOpenIntakeStatistics={displayOpenCaseAndIntakeStatistics}
                    displayClosedIntakeStatistics={displayClosedCaseAndIntakeStatistics}
                    statistics={intakesStatistics}
                  />
                </Grid>
                <Grid xs={12} lg={12} display={"flex"}>
                  <ProvidersStatisticsCard
                    statistics={providersStatistics}
                  />
                </Grid>
                <Grid xs={12} lg={12} display={"flex"}>
                  <FirmDocumentsStatisticsCard
                    statistics={firmDocumentFoldersStatistics}
                  />
                </Grid>
                <Grid xs={12} lg={12} display={"flex"}>
                  <TemplatesStatisticsCard
                    statistics={templateFilesStatistics}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
          
      </Box>
    </>
  );
};

export default OrganizationPage;
