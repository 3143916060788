import { filterValidProperties, getNonNullProperties } from "../../helpers";
import { GetCaseFilesForCaseQueryOptions } from "../../repositories/CasesRepository";
import { JobEntityType } from "./JobEntityType";
import { JobRequestStatus } from "./JobRequestStatus";
import { JobType } from "./JobType";
import { GetFirmDocumentsForFirmDocumentsFolderQueryOptions } from "../../repositories/FirmDocumentsFoldersRepository";

export abstract class JobRequest {
  id!: string;
  organizationId!: string;
  organizationName!: string;
  status!: JobRequestStatus;
  type!: JobType;
  entityType!: JobEntityType;
}
export class DeleteCaseRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.Case;
  caseNumber!: string;
  migrationEntityId!: string;
}
export class DeleteCaseFilesRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.CaseFile;
  migrationEntityId!: string;
  caseNumber!: string;
  filePaths!: { [key: string]: string };
}
export class ExportCaseFilesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.CaseFile;
  exportName!: string;
  exportedCsvUrl?: string;
  filesViewParameters!: GetCaseFilesForCaseQueryOptions;
  getNonNullFilesViewParameters(): Partial<GetCaseFilesForCaseQueryOptions> {
    this.filesViewParameters = filterValidProperties(
      this.filesViewParameters,
      new GetCaseFilesForCaseQueryOptions(),
      ["pageNumber", "pageSize"]
    );
    return getNonNullProperties<GetCaseFilesForCaseQueryOptions>(
      this.filesViewParameters
    );
  }
}
export class ExportCasesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.Case;
  exportName!: string;
  exportedCsvUrl?: string;
}
export class ExportIntakesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.Intake;
  exportName!: string;
  exportedCsvUrl?: string;
}
export class DeleteIntakeRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.Intake;
  intakeNumber!: string;
  migrationEntityId!: string;
}
export class DeleteIntakeFilesRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.IntakeFile;
  migrationEntityId!: string;
  intakeNumber!: string;
  filePaths!: { [key: string]: string };
}

export class ExportFirmDocumentsToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.FirmDocument;
  exportName!: string;
  exportedCsvUrl?: string;
  filesViewParameters!: GetFirmDocumentsForFirmDocumentsFolderQueryOptions;
  getNonNullFilesViewParameters(): Partial<GetFirmDocumentsForFirmDocumentsFolderQueryOptions> {
    this.filesViewParameters = filterValidProperties(
      this.filesViewParameters,
      new GetFirmDocumentsForFirmDocumentsFolderQueryOptions(),
      ["pageNumber", "pageSize"]
    );
    return getNonNullProperties<GetFirmDocumentsForFirmDocumentsFolderQueryOptions>(
      this.filesViewParameters
    );
  }
}
export class ExportFirmDocumentsFoldersToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.FirmDocumentsFolder;
  exportName!: string;
  exportedCsvUrl?: string;
}
export class DeleteFirmDocumentsFolderRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.FirmDocumentsFolder;
  folderPath!: string;
  migrationEntityId!: string;
}
export class DeleteFirmDocumentsRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.FirmDocument;
  migrationEntityId!: string;
  folderPath!: string;
  filePaths!: { [key: string]: string };
}
export class DeleteProviderRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.Provider;
  providerNameId!: string;
  migrationEntityId!: string;
}
export class DeleteProviderFilesRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.ProviderFile;
  migrationEntityId!: string;
  providerNameId!: string;
  filePaths!: { [key: string]: string };
}
export class ExportProviderFilesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.ProviderFile;
  exportName!: string;
  exportedCsvUrl?: string;
  filesViewParameters!: GetCaseFilesForCaseQueryOptions;
  getNonNullFilesViewParameters(): Partial<GetCaseFilesForCaseQueryOptions> {
    this.filesViewParameters = filterValidProperties(
      this.filesViewParameters,
      new GetCaseFilesForCaseQueryOptions(),
      ["pageNumber", "pageSize"]
    );
    return getNonNullProperties<GetCaseFilesForCaseQueryOptions>(
      this.filesViewParameters
    );
  }
}
export class ExportProvidersToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.Provider;
  exportName!: string;
  exportedCsvUrl?: string;
}
export class DeleteTemplateRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.TemplatesLibrary;
  templateNameId!: string;
  migrationEntityId!: string;
}
export class DeleteTemplateFilesRequest extends JobRequest {
  type: JobType.Delete = JobType.Delete;
  entityType: JobEntityType = JobEntityType.TemplateFile;
  migrationEntityId!: string;
  templateNameId!: string;
  filePaths!: { [key: string]: string };
}
export class ExportTemplateFilesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.TemplateFile;
  exportName!: string;
  exportedCsvUrl?: string;
  filesViewParameters!: GetCaseFilesForCaseQueryOptions;
  getNonNullFilesViewParameters(): Partial<GetCaseFilesForCaseQueryOptions> {
    this.filesViewParameters = filterValidProperties(
      this.filesViewParameters,
      new GetCaseFilesForCaseQueryOptions(),
      ["pageNumber", "pageSize"]
    );
    return getNonNullProperties<GetCaseFilesForCaseQueryOptions>(
      this.filesViewParameters
    );
  }
}
export class ExportTemplatesToCsvRequest extends JobRequest {
  type: JobType.ExportCsv = JobType.ExportCsv;
  entityType: JobEntityType = JobEntityType.TemplatesLibrary;
  exportName!: string;
  exportedCsvUrl?: string;
}
export function transformJobRequest(job: JobRequest): JobRequest {
  switch (job.type) {
    case JobType.Delete:
      if (isDeleteCaseRequest(job)) {
        return Object.assign(new DeleteCaseRequest(), job);
      }
      if (isDeleteCaseFilesRequest(job)) {
        return Object.assign(new DeleteCaseFilesRequest(), job);
      }
      if (isDeleteIntakeRequest(job)) {
        return Object.assign(new DeleteIntakeRequest(), job);
      }
      if (isDeleteIntakeFilesRequest(job)) {
        return Object.assign(new DeleteIntakeFilesRequest(), job);
      }
      if (isDeleteFirmDocumentsFolderRequest(job)) {
        return Object.assign(new DeleteFirmDocumentsFolderRequest(), job);
      }
      if (isDeleteFirmDocumentsRequest(job)) {
        return Object.assign(new DeleteFirmDocumentsRequest(), job);
      }
      if (isDeleteProviderRequest(job)) {
        return Object.assign(new DeleteProviderRequest(), job);
      }
      if (isDeleteProviderFilesRequest(job)) {
        return Object.assign(new DeleteProviderFilesRequest(), job);
      }
      if (isDeleteTemplateRequest(job)) {
        return Object.assign(new DeleteTemplateRequest(), job);
      }
      if (isDeleteTemplateFilesRequest(job)) {
        return Object.assign(new DeleteTemplateFilesRequest(), job);
      }
      break;
    case JobType.ExportCsv:
      if (isGenerateCSVFromCaseRequest(job)) {
        return Object.assign(new ExportCasesToCsvRequest(), job);
      }
      if (isGenerateCSVFromCaseFilesRequest(job)) {
        return Object.assign(new ExportCaseFilesToCsvRequest(), job);
      }
      if (isGenerateCSVFromIntakeRequest(job)) {
        return Object.assign(new ExportIntakesToCsvRequest(), job);
      }
      if (isGenerateCSVFromFirmDocumentsFolderRequest(job)) {
        return Object.assign(new ExportFirmDocumentsFoldersToCsvRequest(), job);
      }
      if (isGenerateCSVFromFirmDocumentsRequest(job)) {
        return Object.assign(new ExportFirmDocumentsToCsvRequest(), job);
      }
      if (isGenerateCSVFromProviderRequest(job)) {
        return Object.assign(new ExportProvidersToCsvRequest(), job);
      }
      if (isGenerateCSVFromProviderFilesRequest(job)) {
        return Object.assign(new ExportProviderFilesToCsvRequest(), job);
      }
      if (isGenerateCSVFromTemplateRequest(job)) {
        return Object.assign(new ExportTemplatesToCsvRequest(), job);
      }
      if (isGenerateCSVFromTemplateFilesRequest(job)) {
        return Object.assign(new ExportTemplateFilesToCsvRequest(), job);
      }
      break;
    default:
      throw new Error(`Unknown job type: ${job.type}`);
  }
  throw new Error(`Invalid job data: ${JSON.stringify(job)}`);
}
export function isDeleteCaseRequest(job: JobRequest): job is DeleteCaseRequest {
  return job.type === JobType.Delete && job.entityType === JobEntityType.Case;
}
export function isDeleteCaseFilesRequest(
  job: JobRequest
): job is DeleteCaseFilesRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.CaseFile
  );
}
export function isDeleteIntakeRequest(
  job: JobRequest
): job is DeleteIntakeRequest {
  return job.type === JobType.Delete && job.entityType === JobEntityType.Intake;
}
export function isDeleteIntakeFilesRequest(
  job: JobRequest
): job is DeleteIntakeFilesRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.IntakeFile
  );
}
export function isDeleteFirmDocumentsFolderRequest(
  job: JobRequest
): job is DeleteFirmDocumentsFolderRequest {
  return (
    job.type === JobType.Delete &&
    job.entityType === JobEntityType.FirmDocumentsFolder
  );
}
export function isDeleteFirmDocumentsRequest(
  job: JobRequest
): job is DeleteFirmDocumentsRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.FirmDocument
  );
}
export function isDeleteProviderRequest(
  job: JobRequest
): job is DeleteProviderRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.Provider
  );
}
export function isDeleteProviderFilesRequest(
  job: JobRequest
): job is DeleteProviderFilesRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.ProviderFile
  );
}
export function isDeleteTemplateRequest(
  job: JobRequest
): job is DeleteTemplateRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.TemplatesLibrary
  );
}
export function isDeleteTemplateFilesRequest(
  job: JobRequest
): job is DeleteTemplateFilesRequest {
  return (
    job.type === JobType.Delete && job.entityType === JobEntityType.TemplateFile
  );
}
export function isGenerateCSVFromCaseRequest(
  job: JobRequest
): job is ExportCasesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.Case
  );
}
export function isGenerateCSVFromCaseFilesRequest(
  job: JobRequest
): job is ExportCaseFilesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.CaseFile
  );
}
export function isGenerateCSVFromIntakeRequest(
  job: JobRequest
): job is ExportIntakesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.Intake
  );
}
export function isGenerateCSVFromFirmDocumentsFolderRequest(
  job: JobRequest
): job is ExportFirmDocumentsFoldersToCsvRequest {
  return (
    job.type === JobType.ExportCsv &&
    job.entityType === JobEntityType.FirmDocumentsFolder
  );
}
export function isGenerateCSVFromFirmDocumentsRequest(
  job: JobRequest
): job is ExportFirmDocumentsToCsvRequest {
  return (
    job.type === JobType.ExportCsv &&
    job.entityType === JobEntityType.FirmDocument
  );
}
export function isGenerateCSVFromProviderRequest(
  job: JobRequest
): job is ExportProvidersToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.Provider
  );
}
export function isGenerateCSVFromProviderFilesRequest(
  job: JobRequest
): job is ExportProviderFilesToCsvRequest {
  return (
    job.type === JobType.ExportCsv &&
    job.entityType === JobEntityType.ProviderFile
  );
}
export function isGenerateCSVFromTemplateRequest(
  job: JobRequest
): job is ExportTemplatesToCsvRequest {
  return (
    job.type === JobType.ExportCsv && job.entityType === JobEntityType.TemplatesLibrary
  );
}
export function isGenerateCSVFromTemplateFilesRequest(
  job: JobRequest
): job is ExportTemplateFilesToCsvRequest {
  return (
    job.type === JobType.ExportCsv &&
    job.entityType === JobEntityType.TemplateFile
  );
}
