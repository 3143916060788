import { useTheme } from "@emotion/react";
import React from "react";
import {
  MigrationStatistics,
  calculateFilesStatistics,
} from "../../models/Statistics/MigrationStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface OverallStatisticsCardProps {
  statistics: MigrationStatistics;
  displayOpenCaseAndIntakeStatistics: boolean;
  displayClosedCaseAndIntakeStatistics: boolean;
}

const OverallStatisticsCard: React.FC<OverallStatisticsCardProps> = ({
  statistics,
  displayOpenCaseAndIntakeStatistics,
  displayClosedCaseAndIntakeStatistics
}) => {
  const theme = useTheme() as any;
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = statistics;
  const filesStatistics = calculateFilesStatistics(statistics, displayOpenCaseAndIntakeStatistics, displayClosedCaseAndIntakeStatistics);
  
  let totalFinishedMigrations =
    casesStatistics.caseFoldersMigrated +
    providersStatistics.providersMigrated +
    firmDocumentFoldersStatistics.foldersMigrated +
    templateFilesStatistics.templateLibrariesMigrated;
  if (displayOpenCaseAndIntakeStatistics) totalFinishedMigrations += casesStatistics.openCasesMigrated + intakesStatistics.openIntakesMigrated;
  if (displayClosedCaseAndIntakeStatistics) totalFinishedMigrations += casesStatistics.closedCasesMigrated + intakesStatistics.closedIntakesMigrated;
  
  let totalMigrationsInProgress =
    casesStatistics.caseFoldersInProgress +
    providersStatistics.providersInProgress +
    firmDocumentFoldersStatistics.foldersInProgress +
    templateFilesStatistics.templateLibrariesInProgress;
  if (displayOpenCaseAndIntakeStatistics) totalMigrationsInProgress += casesStatistics.openCasesInProgress + intakesStatistics.openIntakesInProgress;
  if (displayClosedCaseAndIntakeStatistics) totalMigrationsInProgress += casesStatistics.closedCasesInProgress + intakesStatistics.closedIntakesInProgress;
  
  let totalFailedMigrations =
    casesStatistics.caseFoldersInterrupted +
    providersStatistics.providersInterrupted +
    firmDocumentFoldersStatistics.foldersInterrupted +
    templateFilesStatistics.templateLibrariesInterrupted;
  if (displayOpenCaseAndIntakeStatistics) totalFailedMigrations += casesStatistics.openCasesInterrupted + intakesStatistics.openIntakesInterrupted;
  if (displayClosedCaseAndIntakeStatistics) totalFailedMigrations += casesStatistics.closedCasesInterrupted + intakesStatistics.closedIntakesInterrupted;
  
  let totalNumberOfMigrations =
    casesStatistics.numberOfCaseFolders +
    providersStatistics.numberOfProviders +
    firmDocumentFoldersStatistics.numberOfFolders +
    templateFilesStatistics.numberOfTemplateLibraries;
  if (displayOpenCaseAndIntakeStatistics) totalNumberOfMigrations += casesStatistics.numberOfOpenCases + intakesStatistics.numberOfOpenIntakes;
  if (displayClosedCaseAndIntakeStatistics) totalNumberOfMigrations += casesStatistics.numberOfClosedCases + intakesStatistics.numberOfClosedIntakes;
  
  let totalResolvedMigrations = casesStatistics.caseFoldersResolved;
  if (displayOpenCaseAndIntakeStatistics) totalResolvedMigrations += casesStatistics.openCasesResolved + intakesStatistics.openIntakesResolved;
  if (displayClosedCaseAndIntakeStatistics) totalResolvedMigrations += casesStatistics.closedCasesResolved + intakesStatistics.closedIntakesResolved;
  
  let totalAcknowledgedMigrations = casesStatistics.caseFoldersAcknowledged;
  if (displayOpenCaseAndIntakeStatistics) totalAcknowledgedMigrations += casesStatistics.openCasesAcknowledged + intakesStatistics.openIntakesAcknowledged;
  if (displayClosedCaseAndIntakeStatistics) totalAcknowledgedMigrations += casesStatistics.closedCasesAcknowledged + intakesStatistics.closedIntakesAcknowledged;
  
  let filesWithFlaggedStatus = casesStatistics.caseFoldersFlagged;
  if (displayOpenCaseAndIntakeStatistics) filesWithFlaggedStatus += casesStatistics.openCasesFlagged + intakesStatistics.openIntakesFlagged;
  if (displayClosedCaseAndIntakeStatistics) filesWithFlaggedStatus += casesStatistics.closedCasesFlagged + intakesStatistics.closedIntakesFlagged;
  
  const totalFlaggedMigrations = filesWithFlaggedStatus - (totalResolvedMigrations + totalAcknowledgedMigrations);

  const totalNotStartedMigrations =
    totalNumberOfMigrations - 
    totalFinishedMigrations -
    totalFailedMigrations -
    totalMigrationsInProgress -
    totalFlaggedMigrations -
    totalResolvedMigrations -
    totalAcknowledgedMigrations;
  
  const caseChartData = [
    {
      name: "Migrated",
      value: totalFinishedMigrations,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: totalMigrationsInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: totalFailedMigrations,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: totalNotStartedMigrations,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: totalFlaggedMigrations,
      color: theme.palette.primary.plainColor,
    },
    {
      name: "Allowed",
      value: totalResolvedMigrations,
      color: theme.palette.lime.solidBg,
    },
    {
      name: "Acknowledged",
      value: totalAcknowledgedMigrations,
      color: theme.palette.yellow.plainColor,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: filesStatistics.TotalFinishedFiles,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: filesStatistics.TotalNotFoundFiles,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: filesStatistics.TotalFailedFiles,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: filesStatistics.TotalNotStartedFiles,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: filesStatistics.TotalFlaggedFiles,
      color: theme.palette.primary.plainColor,
    },
  ];
  
  return (
    <StatisticsCard
      pieChartSize={250}
      migrationChartData={{ data: caseChartData, label: "Migrations" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      title={"Migration Statistics"}
      totalFilesSizeInGb={filesStatistics.TotalFilesSizeInGb}
      totalMigratedFilesSizeInGb={filesStatistics.MigratedFilesSizeInGb}
      totalFilesSizeInMb={filesStatistics.TotalFilesSizeInMb}
      totalMigratedFilesSizeInMb={filesStatistics.MigratedFilesSizeInMb}
      showFlaggedSizeInfo={false}
    />
  );
};

export default OverallStatisticsCard;
