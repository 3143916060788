import { CasesStatistics } from "./CasesStatistics";
import { FirmDocumentFoldersStatistics } from "./FirmDocumentFoldersStatistics";
import { DiscoveryCycleStatistics } from "./DiscoveryCycle";
import { ProvidersStatistics } from "./ProvidersStatistics";
import { TemplateFilesStatistics } from "./TemplateFilesStatistics";
import { IntakesStatistics } from "./IntakesStatistics";

export interface MigrationStatistics {
  organizationId: string;
  allocatedSharepointSpaceInGb?: number;
  usedSharepointSpaceInGb?: number;
  casesStatistics: CasesStatistics;
  intakesStatistics: IntakesStatistics;
  providersStatistics: ProvidersStatistics;
  firmDocumentFoldersStatistics: FirmDocumentFoldersStatistics;
  templateFilesStatistics: TemplateFilesStatistics;
}

export interface FilesStatistics {
  TotalFilesSizeInGb: number;
  TotalFilesSizeInMb: number;
  MigratedFilesSizeInGb: number;
  MigratedFilesSizeInMb: number;
  MigratedPercentage: number;
  TotalFinishedFiles: number;
  TotalFailedFiles: number;
  TotalNotFoundFiles: number;
  TotalNotStartedFiles: number;
  TotalFlaggedFiles: number;
}

export const calculateTotalFileSize = (
  statistics: MigrationStatistics,
  displayOpenCaseAndIntakeStatistics: boolean,
  displayClosedCaseAndIntakeStatistics: boolean,
  inMb = false,
): number => {
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = statistics;
  if (inMb) {
    let totalSize =
      casesStatistics.totalCaseFolderFilesSizeInMb +
      providersStatistics.totalFilesSizeInMb +
      firmDocumentFoldersStatistics.totalFilesSizeInMb +
      templateFilesStatistics.totalFilesSizeInMb;

    if (displayOpenCaseAndIntakeStatistics)
    {
      totalSize += casesStatistics.totalOpenCaseFilesSizeInMb;
      totalSize += intakesStatistics.totalOpenIntakeFilesSizeInMb;
    }
    if (displayClosedCaseAndIntakeStatistics)
    {
      totalSize += casesStatistics.totalClosedCaseFilesSizeInMb;
      totalSize += intakesStatistics.totalClosedIntakeFilesSizeInMb;
    }
    
    return totalSize;
  } else {
    let totalSize =
      casesStatistics.totalCaseFolderFilesSizeInGb +
      providersStatistics.totalFilesSizeInGb +
      firmDocumentFoldersStatistics.totalFilesSizeInGb +
      templateFilesStatistics.totalFilesSizeInGb

    if (displayOpenCaseAndIntakeStatistics)
    {
      totalSize += casesStatistics.totalOpenCaseFilesSizeInGb;
      totalSize += intakesStatistics.totalOpenIntakeFilesSizeInGb;
    }
    if (displayClosedCaseAndIntakeStatistics)
    {
      totalSize += casesStatistics.totalClosedCaseFilesSizeInGb;
      totalSize += intakesStatistics.totalClosedIntakeFilesSizeInGb;
    }

    return totalSize;
  }
};

export const calculateMigratedFilesSize = (
  statistics: MigrationStatistics,
  displayOpenCaseAndIntakeStatistics: boolean,
  displayClosedCaseAndIntakeStatistics: boolean,
  inMb = false,
): number => {
  let migratedFilesSize = 0;
  if (inMb) {
    migratedFilesSize += statistics.casesStatistics.totalMigratedCaseFolderFilesSizeInMb || 0;
    migratedFilesSize += statistics.providersStatistics.totalMigratedFilesSizeInMb || 0;
    migratedFilesSize += statistics.firmDocumentFoldersStatistics.totalMigratedFilesSizeInMb || 0;
    migratedFilesSize += statistics.templateFilesStatistics.totalMigratedFilesSizeInMb || 0;

    if (displayOpenCaseAndIntakeStatistics)
    {
      migratedFilesSize += statistics.casesStatistics.totalMigratedOpenCaseFilesSizeInMb || 0;
      migratedFilesSize += statistics.intakesStatistics.totalMigratedOpenIntakeFilesSizeInMb || 0;
    }
    if (displayClosedCaseAndIntakeStatistics)
    {
      migratedFilesSize += statistics.casesStatistics.totalMigratedClosedCaseFilesSizeInMb || 0;
      migratedFilesSize += statistics.intakesStatistics.totalMigratedClosedIntakeFilesSizeInMb || 0;
    }
  } else {
    migratedFilesSize += statistics.casesStatistics.totalMigratedCaseFolderFilesSizeInGb || 0;
    migratedFilesSize += statistics.providersStatistics.totalMigratedFilesSizeInGb || 0;
    migratedFilesSize += statistics.firmDocumentFoldersStatistics.totalMigratedFilesSizeInGb || 0;
    migratedFilesSize += statistics.templateFilesStatistics.totalMigratedFilesSizeInGb || 0;

    if (displayOpenCaseAndIntakeStatistics)
    {
      migratedFilesSize += statistics.casesStatistics.totalMigratedOpenCaseFilesSizeInGb || 0;
      migratedFilesSize += statistics.intakesStatistics.totalMigratedOpenIntakeFilesSizeInGb || 0;
    }
    if (displayClosedCaseAndIntakeStatistics)
    {
      migratedFilesSize += statistics.casesStatistics.totalMigratedClosedCaseFilesSizeInGb || 0;
      migratedFilesSize += statistics.intakesStatistics.totalMigratedClosedIntakeFilesSizeInGb || 0;
    }
  }
  return migratedFilesSize;
};

export const calculateFilesStatistics = (
  statistics: MigrationStatistics,
  displayOpenCaseAndIntakeStatistics: boolean,
  displayClosedCaseAndIntakeStatistics: boolean
): FilesStatistics => {
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = statistics;
  
  const totalFileSizeInGb = calculateTotalFileSize(statistics, displayOpenCaseAndIntakeStatistics, displayClosedCaseAndIntakeStatistics);
  const migratedFilesSizeInGb = calculateMigratedFilesSize(statistics, displayOpenCaseAndIntakeStatistics, displayClosedCaseAndIntakeStatistics);
  const totalFileSizeInMb = calculateTotalFileSize(statistics, displayOpenCaseAndIntakeStatistics, displayClosedCaseAndIntakeStatistics, true);
  const migratedFilesSizeInMb = calculateMigratedFilesSize(statistics, displayOpenCaseAndIntakeStatistics, displayClosedCaseAndIntakeStatistics, true);

  let totalFinishedFiles =
    casesStatistics.caseFolderFilesMigrated +
    providersStatistics.filesMigrated +
    firmDocumentFoldersStatistics.filesMigrated +
    templateFilesStatistics.filesMigrated;
  if (displayOpenCaseAndIntakeStatistics) totalFinishedFiles += casesStatistics.openCaseFilesMigrated + intakesStatistics.openIntakeFilesMigrated;
  if (displayClosedCaseAndIntakeStatistics) totalFinishedFiles += casesStatistics.closedCaseFilesMigrated + intakesStatistics.closedIntakeFilesMigrated;
    
  let totalFailedFiles =
    casesStatistics.caseFoldersInterrupted +
    providersStatistics.filesInterrupted +
    firmDocumentFoldersStatistics.filesInterrupted +
    templateFilesStatistics.filesInterrupted;
  if (displayOpenCaseAndIntakeStatistics) totalFailedFiles += casesStatistics.openCaseFilesInterrupted + intakesStatistics.openIntakeFilesInterrupted;
  if (displayClosedCaseAndIntakeStatistics) totalFailedFiles += casesStatistics.closedCaseFilesInterrupted + intakesStatistics.closedIntakeFilesInterrupted;
  
  let totalNotFoundFiles =
    casesStatistics.caseFolderFilesNotFound +
    providersStatistics.filesNotFound +
    firmDocumentFoldersStatistics.filesNotFound +
    templateFilesStatistics.filesNotFound;
  if (displayOpenCaseAndIntakeStatistics) totalNotFoundFiles += casesStatistics.openCaseFilesNotFound + intakesStatistics.openIntakeFilesNotFound;
  if (displayClosedCaseAndIntakeStatistics) totalNotFoundFiles += casesStatistics.closedCaseFilesNotFound + intakesStatistics.closedIntakeFilesNotFound;
  
  let totalFlaggedFiles = casesStatistics.caseFolderFilesFlagged;
  if (displayOpenCaseAndIntakeStatistics) totalFlaggedFiles += casesStatistics.openCaseFilesFlagged + intakesStatistics.openIntakeFilesFlagged;
  if (displayClosedCaseAndIntakeStatistics) totalFlaggedFiles += casesStatistics.closedCaseFilesFlagged + intakesStatistics.closedIntakeFilesFlagged;
  
  let totalNumberOfFiles =
    casesStatistics.totalNumberOfCaseFolderFiles +
    providersStatistics.totalNumberOfFiles +
    firmDocumentFoldersStatistics.totalNumberOfFiles +
    templateFilesStatistics.totalNumberOfFiles;
  if (displayOpenCaseAndIntakeStatistics) totalNumberOfFiles += casesStatistics.totalNumberOfOpenCaseFiles + intakesStatistics.totalNumberOfOpenIntakeFiles;
  if (displayClosedCaseAndIntakeStatistics) totalNumberOfFiles += casesStatistics.totalNumberOfClosedCaseFiles + intakesStatistics.totalNumberOfClosedIntakeFiles;
  
  const totalNotStartedFiles =
    totalNumberOfFiles -
    totalFinishedFiles -
    totalFailedFiles -
    totalNotFoundFiles -
    totalFlaggedFiles;

  return {
    TotalFilesSizeInGb: totalFileSizeInGb,
    TotalFilesSizeInMb: totalFileSizeInMb,
    MigratedFilesSizeInGb: migratedFilesSizeInGb,
    MigratedFilesSizeInMb: migratedFilesSizeInMb,
    MigratedPercentage: (migratedFilesSizeInGb / totalFileSizeInGb) * 100,
    TotalFinishedFiles: totalFinishedFiles,
    TotalFailedFiles: totalFailedFiles,
    TotalNotFoundFiles: totalNotFoundFiles,
    TotalNotStartedFiles: totalNotStartedFiles,
    TotalFlaggedFiles: totalFlaggedFiles,
  };
};
