export enum AuditLogType {
  ClientConfigurationChange = "clientConfigurationChange",
  OrganizationConfigurationChange = "organizationConfigurationChange",
  CasesExport = "casesExport",
  CaseFilesExport = "caseFilesExport",
  IntakesExport = "intakesExport",
  IntakeFilesExport = "intakeFilesExport",
  IntakeFileDownload = "intakeFileDownload",
  CaseFileDownload = "caseFileDownload",
  FirmDocumentsFoldersExport = "firmDocumentsFoldersExport",
  FirmDocumentsExport = "firmDocumentsExport",
  FirmDocumentDownload = "firmDocumentDownload",
  ProvidersExport = "providersExport",
  ProviderFilesExport = "providerFilesExport",
  ProviderFileDownload = "providerFileDownload",
  TemplatesExport = "templatesExport",
  TemplateFilesExport = "templateFilesExport",
  TemplateFileDownload = "templateFileDownload",
}

export const AuditLogTypeLabels: Record<AuditLogType, string> = {
  [AuditLogType.ClientConfigurationChange]: "Client Configuration Change",
  [AuditLogType.OrganizationConfigurationChange]:
    "Organization Configuration Change",
  [AuditLogType.CasesExport]: "Cases Export",
  [AuditLogType.CaseFilesExport]: "Case Files Export",
  [AuditLogType.CaseFileDownload]: "Case File Download",
  [AuditLogType.IntakesExport]: "Intakes Export",
  [AuditLogType.IntakeFilesExport]: "Intake Files Export",
  [AuditLogType.IntakeFileDownload]: "Intake File Download",
  [AuditLogType.FirmDocumentsFoldersExport]: "Firm Documents Folders Export",
  [AuditLogType.FirmDocumentsExport]: "Firm Documents Export",
  [AuditLogType.FirmDocumentDownload]: "Firm Document Download",
  [AuditLogType.ProvidersExport]: "Providers Export",
  [AuditLogType.ProviderFilesExport]: "Provider Files Export",
  [AuditLogType.ProviderFileDownload]: "Provider File Download",
  [AuditLogType.TemplatesExport]: "Templates Export",
  [AuditLogType.TemplateFilesExport]: "Template Files Export",
  [AuditLogType.TemplateFileDownload]: "Template File Download",
};

export interface AuditLog {
  id: string;
  entityId: string | null;
  eventType: AuditLogType;
  oldValue: string | null;
  newValue: string | null;
  additionalData: string | null;
  performedBy: string;
  eventDate: string;
  performerEmailAddress: string;
}
