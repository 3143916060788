import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { DropboxRootFolder } from "../../models/ClientConfiguration/DropboxRootFolder";
import OrganizationsRepository from "../../repositories/OrganizationsRepository";
import { DropboxRefreshToken } from "../../models/OrganizationConfiguration/DropboxRefreshToken";
import OutlinedDiv from "../common/OutlinedDiv";
import DropboxRootFoldersConfigurationControl from "./DropboxRootFoldersConfigurationControl";

import {
  Input,
  Select,
  Option,
  Grid,
  FormControl,
  FormLabel,
  IconButton,
  Switch,
  Skeleton,
} from "@mui/joy";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ImplementationType } from "../../models/OrganizationConfiguration/ImplementationType";

interface ApiSectionProps {
  organizationId: string;
  implementationType: ImplementationType;
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const ApiSection: React.FC<ApiSectionProps> = ({
  organizationId,
  implementationType,
  value,
  onChange,
}) => {
  const organizationsRepository = new OrganizationsRepository();
  const [dropboxRefreshTokens, setDropboxRefreshTokens] = useState<
    DropboxRefreshToken[]
  >([]);
  const [showFilevineApiKey, setShowFilevineApiKey] = useState(false);
  const [showFilevineApiSecret, setShowFilevineApiSecret] = useState(false);
  const [showDropboxAppSecret, setShowDropboxAppSecret] = useState(false);

  const dropboxRefreshTokensQuery = useQuery(
    ["dropboxRefreshTokens", organizationId],
    async () => {
      return await organizationsRepository.getOrganizationDropboxRefreshTokens(
        organizationId
      );
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    if (dropboxRefreshTokensQuery.isSuccess && dropboxRefreshTokensQuery.data)
      setDropboxRefreshTokens(dropboxRefreshTokensQuery.data);
  }, [
    dropboxRefreshTokensQuery.data,
    dropboxRefreshTokensQuery.isSuccess,
    dropboxRefreshTokensQuery.isLoading,
    dropboxRefreshTokensQuery.isError,
  ]);

  const handleMaxRequestsPerSecondChange = (maxRequestsPerSecond: string) => {
    if (maxRequestsPerSecond.trim() === "") {
      onChange({
        ...value,
        sourceApiMaxRequestsPerSecond: null,
      });
      return;
    }

    const parsedMaxUploads = parseInt(maxRequestsPerSecond);
    if (!isNaN(parsedMaxUploads) && parsedMaxUploads > 0) {
      onChange({
        ...value,
        sourceApiMaxRequestsPerSecond: parsedMaxUploads,
      });
    }
  };

  const handleMaxRequestsPerHourChange = (maxRequestsPerHour: string) => {
    if (maxRequestsPerHour.trim() === "") {
      onChange({
        ...value,
        sourceApiMaxRequestsPerHour: null,
      });
      return;
    }

    const parsedMaxUploads = parseInt(maxRequestsPerHour);
    if (!isNaN(parsedMaxUploads) && parsedMaxUploads > 0) {
      onChange({
        ...value,
        sourceApiMaxRequestsPerHour: parsedMaxUploads,
      });
    }
  };

  const handleMaxRequestsPerDayChange = (maxRequestsPerDay: string) => {
    if (maxRequestsPerDay.trim() === "") {
      onChange({
        ...value,
        sourceApiMaxRequestsPerDay: null,
      });
      return;
    }

    const parsedMaxUploads = parseInt(maxRequestsPerDay);
    if (!isNaN(parsedMaxUploads) && parsedMaxUploads > 0) {
      onChange({
        ...value,
        sourceApiMaxRequestsPerDay: parsedMaxUploads,
      });
    }
  };

  const handleDropboxRootFoldersChange = (
    dropboxRootFolders: DropboxRootFolder[]
  ) => {
    onChange({
      ...value,
      dropboxRootFolders: dropboxRootFolders,
    });
  };

  const selectedToken = dropboxRefreshTokens.find(
    (token) => token.refreshToken === value.dropboxRefreshToken
  );

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 0 }}>
        <Grid xs={4} minWidth="25%">
          <FormControl>
            <FormLabel>Request limit per second</FormLabel>
            <Input
              type="number"
              onChange={(event) =>
                handleMaxRequestsPerSecondChange(event.target.value)
              }
              value={
                value.sourceApiMaxRequestsPerSecond === null
                  ? ""
                  : value.sourceApiMaxRequestsPerSecond
              }
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid xs={4} minWidth="25%">
          <FormControl>
            <FormLabel>Request limit per hour</FormLabel>
            <Input
              type="number"
              onChange={(event) =>
                handleMaxRequestsPerHourChange(event.target.value)
              }
              value={
                value.sourceApiMaxRequestsPerHour === null
                  ? ""
                  : value.sourceApiMaxRequestsPerHour
              }
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid xs={4} minWidth="25%">
          <FormControl>
            <FormLabel>Request limit per day</FormLabel>
            <Input
              type="number"
              onChange={(event) =>
                handleMaxRequestsPerDayChange(event.target.value)
              }
              value={
                value.sourceApiMaxRequestsPerDay === null
                  ? ""
                  : value.sourceApiMaxRequestsPerDay
              }
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>

      {implementationType === ImplementationType.Filevine && (
        <>
          <FormControl sx={{ mb: 0.5 }}>
            <FormLabel>Base URL</FormLabel>
            <Input
              value={value.filevineBaseUrl}
              onChange={(event) =>
                onChange({
                  ...value,
                  filevineBaseUrl: event.target.value,
                })
              }
              fullWidth
            />
          </FormControl>

          <FormControl sx={{ mb: 0.5 }}>
            <FormLabel>API Key</FormLabel>
            <Input
              type={showFilevineApiKey ? "text" : "password"}
              value={value.filevineApiKey}
              onChange={(event) =>
                onChange({
                  ...value,
                  filevineApiKey: event.target.value,
                })
              }
              fullWidth
              endDecorator={
                <IconButton
                  onClick={() => setShowFilevineApiKey((prev) => !prev)}
                  size="sm"
                  sx={{ color: "inherit" }}
                >
                  {showFilevineApiKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
          </FormControl>

          <FormControl sx={{ mb: 1.5 }}>
            <FormLabel>API Secret</FormLabel>
            <Input
              type={showFilevineApiSecret ? "text" : "password"}
              value={value.filevineApiSecret}
              onChange={(event) =>
                onChange({
                  ...value,
                  filevineApiSecret: event.target.value,
                })
              }
              fullWidth
              endDecorator={
                <IconButton
                  onClick={() => setShowFilevineApiSecret((prev) => !prev)}
                  size="sm"
                  sx={{ color: "inherit" }}
                >
                  {showFilevineApiSecret ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
          </FormControl>

          <OutlinedDiv label="">
            <FormControl
              orientation="horizontal"
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <FormLabel>Use Batch Endpoint</FormLabel>
              <Switch
                checked={value.filevineUseBatchEndpoint}
                onChange={(event) =>
                  onChange({
                    ...value,
                    filevineUseBatchEndpoint: event.target.checked,
                  })
                }
              />
            </FormControl>
          </OutlinedDiv>
        </>
      )}

      {implementationType === ImplementationType.Dropbox && (
        <>
          <FormControl sx={{ mb: 0.5 }}>
            <FormLabel>API ID</FormLabel>
            <Input
              value={value.dropboxApiId}
              onChange={(event) =>
                onChange({
                  ...value,
                  dropboxApiId: event.target.value,
                })
              }
              fullWidth
            />
          </FormControl>

          <FormControl sx={{ mb: 0.5 }}>
            <FormLabel>App Secret</FormLabel>
            <Input
              type={showDropboxAppSecret ? "text" : "password"}
              value={value.dropboxAppSecret}
              onChange={(event) =>
                onChange({
                  ...value,
                  dropboxAppSecret: event.target.value,
                })
              }
              fullWidth
              endDecorator={
                <IconButton
                  onClick={() => setShowDropboxAppSecret((prev) => !prev)}
                  size="sm"
                  sx={{ color: "inherit" }}
                >
                  {showDropboxAppSecret ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
          </FormControl>

          <FormControl>
            <FormLabel>Refresh Token</FormLabel>
            <Skeleton
              variant="inline"
              loading={dropboxRefreshTokensQuery.isLoading}
            >
              <Select
                value={selectedToken ? selectedToken : null}
                onChange={(_, newValue) => {
                  onChange({
                    ...value,
                    dropboxRefreshToken: newValue?.refreshToken || "",
                    dropboxAccountType: newValue?.accountType,
                  });
                }}
              >
                {dropboxRefreshTokens.length > 0 ? (
                  dropboxRefreshTokens.map((token: DropboxRefreshToken) => (
                    <Option key={token.id} value={token}>
                      {token.name}
                    </Option>
                  ))
                ) : (
                  <Option disabled value="">
                    No tokens available
                  </Option>
                )}
              </Select>
            </Skeleton>
          </FormControl>

          <DropboxRootFoldersConfigurationControl
            dropboxRootFolders={value.dropboxRootFolders || []}
            onChange={handleDropboxRootFoldersChange}
          />
        </>
      )}
    </>
  );
};

export default ApiSection;
