import { ColorPaletteProp } from "@mui/joy";

export enum JobEntityType {
  Case = "Case",
  CaseFile = "CaseFile",
  Intake = "Intake",
  IntakeFile = "IntakeFile",
  Provider = "Provider",
  ProviderFile = "ProviderFile",
  TemplateFile = "TemplateFile",
  FirmDocumentsFolder = "FirmDocumentsFolder",
  FirmDocument = "FirmDocument",
  TemplatesLibrary = "TemplatesLibrary",
}
export const JobEntityTypeLabels: Record<JobEntityType, string> = {
  [JobEntityType.Case]: "Case",
  [JobEntityType.CaseFile]: "Case File",
  [JobEntityType.Intake]: "Intake",
  [JobEntityType.IntakeFile]: "Intake File",
  [JobEntityType.Provider]: "Provider",
  [JobEntityType.ProviderFile]: "Provider File",
  [JobEntityType.TemplateFile]: "Template File",
  [JobEntityType.FirmDocumentsFolder]: "Firm Documents Folder",
  [JobEntityType.FirmDocument]: "Firm Document",
  [JobEntityType.TemplatesLibrary]: "TemplatesLibrary",
};

export const JobEntityTypeColors: Record<JobEntityType, ColorPaletteProp> = {
  [JobEntityType.Case]: "primary",
  [JobEntityType.CaseFile]: "primary",
  [JobEntityType.Intake]: "success",
  [JobEntityType.IntakeFile]: "primary",
  [JobEntityType.Provider]: "lime",
  [JobEntityType.ProviderFile]: "lime",
  [JobEntityType.TemplateFile]: "neutral",
  [JobEntityType.FirmDocumentsFolder]: "danger",
  [JobEntityType.FirmDocument]: "danger",
  [JobEntityType.TemplatesLibrary]: "warning",
};
