import { useTheme } from "@emotion/react";
import React from "react";
import { TemplateFilesStatistics } from "../../models/Statistics/TemplateFilesStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface TemplatesStatisticsCardProps {
  statistics: TemplateFilesStatistics;
}

const TemplatesStatisticsCard: React.FC<TemplatesStatisticsCardProps> = ({
  statistics,
}) => {
  const theme = useTheme() as any;
  const caseChartData = [
    {
      name: "Migrated",
      value: statistics.templateLibrariesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: statistics.templateLibrariesInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.templateLibrariesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.numberOfTemplateLibraries -
        (statistics.templateLibrariesMigrated +
          statistics.templateLibrariesInterrupted +
          statistics.templateLibrariesInProgress +
          statistics.templateLibrariesFlagged),
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: statistics.templateLibrariesFlagged,
      color: theme.palette.primary.plainColor,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: statistics.filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: statistics.filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.totalNumberOfFiles -
        (statistics.filesMigrated +
          statistics.filesInterrupted +
          statistics.filesNotFound +
          statistics.filesFlagged),
      color: theme.palette.text.secondary,
    },
  ];
  const flagsChartData = [
    {
      name: "Blocked by user",
      value: statistics.filesInTemplateLibrariesBlockedByUser,
      color: theme.palette.warning[400],
    }
  ];
  
  return (
    <StatisticsCard
      migrationChartData={{ data: caseChartData, label: "Template Libraries" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      fileFlagsChartData={{ data: flagsChartData, label: "Flags"}}
      title={"Template Files Statistics"}
      totalFilesSizeInGb={statistics.totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={statistics.totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={statistics.totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={statistics.totalMigratedFilesSizeInMb}
      showFlaggedSizeInfo={false}
    />
  );
};

export default TemplatesStatisticsCard;
