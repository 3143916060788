import { AxiosResponse } from "axios";
import { Template, TemplateWithStatistics } from "../models/Template";
import { Paging } from "../models/Paging";
import useApi from "../services/ApiService";
import { saveAs } from "file-saver";
import { TemplateFile } from "../models/TemplateFile";

export interface GetTemplatesQueryOptions {
  organizationId?: string;
  status?: string[] | null;
  isLocked?: boolean | null;
  createdBy?: string[];
  templateNameId?: string[] | null;
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}
export class GetTemplateFilesForTemplateQueryOptions {
  status?: string[] | null;
  extension?: string[] | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
  filePath?: string | null;
  minFileSize?: number | null;
  maxFileSize?: number | null;
  type?: number | null;
}

export class TemplatesRepository {
  private api = useApi();

  async getTemplates(
    options: GetTemplatesQueryOptions = {}
  ): Promise<{ data: Template[]; paging: Paging | null }> {
    const {
      status = null,
      isLocked = null,
      createdBy = null,
      templateNameId = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;

    try {
      const response = await this.api.get("/api/Templates/libraries", {
        params: {
          Status: status,
          IsLocked: isLocked,
          CreatedBy: createdBy,
          TemplateNameId: templateNameId,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch templates");
    }
  }

  async getTemplate(id: string): Promise<TemplateWithStatistics> {
    try {
      const response = await this.api.get(`/api/Templates/${id}`);
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch template with id: ${id}`);
    }
  }

  async getTemplateFilesForTemplate(
    id: string,
    options: GetTemplateFilesForTemplateQueryOptions = {}
  ): Promise<{ data: TemplateFile[]; paging: Paging | null }> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
    } = options;
    try {
      const response = await this.api.get(`/api/TemplateFiles/${id}/files`, {
        params: {
          Status: status,
          Extension: extension,
          FilePath: filePath,
          MinFileSize: minFileSize,
          MaxFileSize: maxFileSize,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
        timeout: 100 * 1000,
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch template with id: ${id}`);
    }
  }
  async updateTemplateBlock(templateId: string, block: boolean): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: templateId,
        block: block,
      };

      const response: AxiosResponse<void> = await this.api.patch(
        `/api/Templates/updateBlock`,
        data
      );

      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to update block info of template with id: ${templateId}`
      );
    }
  }

  async downloadSharepointFile(
    templateId: string,
    templateFileId: string
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: templateId,
        fileId: templateFileId,
      };

      const response: AxiosResponse<any> = await this.api.post(
        `/api/templates/sharepoint/download`,
        data
      );
      saveAs(new Blob([response.data.file]), response.data.name);
    } catch (error) {
      throw new Error(
        `Failed to download file for templateId: ${templateId} and templateFileId: ${templateFileId}`
      );
    }
  }
}

export default TemplatesRepository;
